.splide {
    position: relative;
    visibility: hidden;
    padding-bottom: 2rem;

    &-wrapper {
        padding-top: 5rem;
        background-color: $c-elkem-grey;

        &.white {
            background-color: white;
        }

        .article-page &,
        .start-page & {
            margin-right: -15px;
            margin-left: -15px;

            @include tablet {
                margin-right: -60px;
                margin-left: -60px;
            }

            @include phone {
                margin-right: -24px;
                margin-left: -24px;
            }
        }
    }

    &-heading {
        max-width: 1150px;
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 3rem;
        text-transform: uppercase;
        text-align: center;
        color: $c-charcoal;
        border-bottom: 1px solid #d9d9d9;
    }

    &.is-initialized,
    &.is-rendered {
        visibility: visible;
    }

    &__list {
        backface-visibility: hidden;
        display: flex;
        gap: 20px;
        height: 100%;
        margin: 0!important;
        padding: 0!important;

        .splide__slide + .splide__slide {
            margin-top: 0;
        }
    }

    &__track {
        padding: 40px 20px !important;
    }

    &__slide {
        flex-shrink: 0;
        -ms-flex-negative: 0;
        position: relative;
        margin: 0;
        list-style-type: none !important;
        backface-visibility: hidden;
        box-sizing: border-box;

        a {
            display: block;
            overflow: hidden;
            color: inherit;
            background-color: $c-white;
            border-radius: 12px;

            .white & {
                background-color: $c-elkem-grey;

                .svg-icon {
                    background-color: $c-elkem-grey;
                }
            }

            .article-text .body & {
                padding-right: 0;
                padding-left: 0;
                border-bottom: none;
            }

            &:hover,
            &:focus {
                color: inherit;

                .article-text .body & {
                    text-decoration: underline;
                    background-color: $c-white;
                }

                .article-text .body .white & {
                    background-color: $c-elkem-grey;
                }
            }

            .magazine-featured & {
                &,
                &:hover,
                &:focus {
                    color: #000;
                }
            }
        }

        &-image {
            height: 172px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &-text {
            display: flex;
            min-height: 146px;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 20px;
            font-family: $font-family-medium;
            font-size: 1.25rem;

            h2 {
                margin-top: 0;
                margin-bottom: 0;
                padding: 20px;
                font-size: 1.5rem;

                + p {
                    margin-top: -1rem;
                    margin-bottom: 1rem;
                    margin-left: 20px;
                    color: #000;
                }
            }

            a {
                text-decoration: none;

                h2 span {
                    border-bottom: 2px solid $c-red;
                }
            }
        }
    }

    &__spinner {
        animation: splide-loading 1s linear infinite;
        border: 2px solid #999;
        border-left-color: transparent;
        border-radius: 50%;
        bottom: 0;
        contain: strict;
        display: inline-block;
        height: 20px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
    }

    &__sr {
        clip: rect(0 0 0 0);
        border: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    &__toggle {
        &.is-active .splide__toggle__play,
        &__pause {
            display: none;
        }

        &.is-active .splide__toggle__pause {
            display: inline;
        }
    }

    &__track {
        overflow: hidden;
        position: relative;
        z-index: 0;

        &--draggable {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &--fade>.splide__list {
            display: block;
        }

        &--fade>.splide__list>.splide__slide {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: 0;
        }

        &--fade>.splide__list>.splide__slide.is-active {
            opacity: 1;
            position: relative;
            z-index: 1;
        }

        &--ttb>.splide__list {
            display: block;
        }

        &--nav>.splide__list>.splide__slide {
            border: 3px solid transparent;
            cursor: pointer;

            &.is-active {
                border: 3px solid #000;
            }
        }
    }

    &--rtl {
        direction: rtl;
    }

    &__slide {
        width: 256px;
        -webkit-tap-highlight-color: rgba(0,0,0,0);

        &:focus {
            outline: 0;
        }
    }
    &.is-overflow .splide__slide.is-active a {
        box-shadow: 0 0 14px 0px #00000055;
    }

    &__toggle {
        cursor: pointer;
    }

    &__arrows {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        top: -90px;
        right: 30px;

        @include phone {
            display: none;
        }
    }
    &:not(.is-overflow) .splide__arrows {
        display: none;
    }

    &__arrow {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2em;
        height: 2em;
        padding: 0;
        background: transparent;
        border: 0;
        opacity: .7;

        svg {
            fill: black;
            height: 1.2em;
            width: 1.2em;
        }

        &--prev {
            svg {
                transform: rotate(180deg);
            }
        }

        &:not(:disabled) {
            cursor: pointer;

            &:hover {
                opacity: .9;
            }
        }

        &:disabled {
            opacity: .15;
        }
    }

    &-slider-progress {
        margin: 30px 10vw 50px;
        background: #d9d9d9;

        @include tablet-up {
            display: none;
        }

        &-bar {
            display: flex;
            justify-content: flex-end;
            height: 2px;
            transition: width 400ms ease;
            width: 0;

            div {
                width: 0;
                height: 2px;
                background: $c-charcoal;
            }
        }
    }

    &__pagination {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;
        margin-left: 30px;
        pointer-events: none;

        @include phone {
            display: none;
        }
    }
    &__pagination li {
        display: inline-block;
        margin: 0;
        line-height: 1;
        list-style-type: none;
        pointer-events: auto;
    }
    &:not(.is-overflow) .splide__pagination {
        display: none;
    }
    &__pagination__page {
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 40px;
        height: 25px;
        margin: 4px;
        padding: 0;
        background: transparent;
        border: 0;
        transition: transform .2s linear;

        &::after {
            content: '';
            width: 100%;
            height: 3px;
            background-color: $c-gray-xxlight;
        }
    }
    &__pagination__page.is-active::after {
        z-index: 1;
        background: $c-black;
    }
    &__pagination__page:hover {
        cursor: pointer;
    }
    // &__pagination__page:focus-visible::after {
    // }
    // &.is-focus-in .splide__pagination__page:focus {
    // }

    &.off {
        .splide__list {
            justify-content: center;
            height: 100%;
            text-align: center;
            transform: none !important;
        }

        .splide__slide {
            flex-shrink: 0;
            -ms-flex-negative: 0;
            display: inline-block;
            position: relative;
            margin: 0;
            list-style-type: none !important;
            backface-visibility: hidden;
            box-sizing: border-box;

            &:first-child {
                margin-left: 20px;
            }
        }

        .splide-slider-progress-bar {
            width: 100% !important;

            &,
            div {
                height: 0;
            }
        }
    }
}

@keyframes splide-loading {
    0% { transform: rotate(0); }
    to { transform: rotate(1turn); }
}

@supports(outline-offset: -3px) {
    .splide__slide:focus-visible {
        outline: 3px solid #0bf;
        outline-offset: -3px;
    }
}
@media screen and (-ms-high-contrast: none) {
    .splide__slide:focus-visible {
        border: 3px solid #0bf;
    }
}
@supports(outline-offset: -3px) {
    .splide.is-focus-in .splide__slide:focus {
        outline: 3px solid #0bf;
        outline-offset: -3px;
    }
}
@media screen and (-ms-high-contrast: none) {
    .splide.is-focus-in {
        .splide__slide:focus {
            border: 3px solid #0bf;
        }

        .splide__track>.splide__list>.splide__slide:focus {
            border-color: #0bf;
        }
    }
}

// Helper for Page Carousel in narrower article grid
.pos-static {
    position: static !important;

    .splide-wrapper {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        max-width: 100%;

        &.lift-up {
            margin: -720px 0 0;
        }
    }

    .splide-shadow {
        position: relative;
        display: block;
        width: 100%;
        height: 740px;
    }

    &.article-text .splide__pagination li {
        margin-top: 0;
    }
}
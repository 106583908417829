﻿$sm-min-width: 480px;
$md-min-width: 768px;
$lg-min-width: 992px;
$xl-min-width: 1200px;
$xxl-min-width: 1440px;
$max-content-width: 1396px;//1436px
$max-content-width-narrow: 1048px;

$c-gray: #f0f0f0;
$c-gray-hover: #ddd;
$c-gray-xxlight: #999b9b;
$c-gray-xlight: #f8f8f8;
$c-gray-light: #ccc;
$c-gray-medium: #999;
$c-gray-dark: #666;
$c-red: #b5282e;
$c-blue: #20659d;

$c-corporate: #ec1211;
$c-corporate-darker: darken($c-corporate, 10%);
$c-silicones: #248ffc;
$c-silicones-darker: darken($c-silicones, 10%);
$c-silicon-products: #7aea97;
$c-silicon-products-darker: darken($c-silicon-products, 10%);
$c-carbon: #a673f7;
$c-carbon-darker: darken($c-carbon, 10%);

$c-rose: #ffeaea;
$c-beige: #f0e3d6;
$c-wheat: #f3f0e4;
$c-linen: #faf2ea;
$c-white: #ffffff;
$c-wild-sand: #f6f6f6;
$c-concrete: #efedee;
$c-elkem-grey: #f0eded;
$c-chatelle: #c3bfc4;
$c-ash: #686b6e;
$c-abbey: #4d5053;
$c-mineshaft: #3c3c3b;
$c-charcoal: #333638;
$c-charcoal-light: #f0eded;
$c-black: #000;

$c-white-95: rgba(255, 255, 255, 0.95);
$c-white-90: rgba(255, 255, 255, 0.9);
$c-white-75: rgba(255, 255, 255, 0.75);
$c-white-50: rgba(255, 255, 255, 0.5);
$c-white-25: rgba(255, 255, 255, 0.25);
$c-white-10: rgba(255, 255, 255, 0.1);

$c-black-75: rgba(0, 0, 0, 0.75);

$font-family-book: "ABC Favorit Book", "Helvetica Neue", Arial;
$font-family-medium: "ABC Favorit Medium", "Helvetica Neue", Arial;
$font-size-base: 1rem;//16px
$font-size-mobile: 1rem;//16px - 1.125rem 18px

$line-height-lg: 1.42;
$line-height-sm: 1.42;

$link-color: $c-black;
$link-hover-color: $c-corporate;

$paragraph-spacing: 1.5rem;
$block-heading-font-size: 2rem;
$block-paragraph-font-size: 1.125rem;
$element-spacing-unit: 1rem;

@function element-spacing-units($factor: 1) {
    @return calc(#{$element-spacing-unit} * #{$factor});
}

$padding-size: 2rem;
$padding-size-plus: 4rem;
$padding-size-minus: 1rem;

$btn-padding-x: 12px;
$btn-padding-y: 6px;
$btn-line-height: inherit;
$btn-border-radius: 2px;

$btn-focus-box-shadow: 0 0 0;

$custom-select-color: $c-black;
$custom-select-disabled-color: $c-ash;
$custom-select-bg: $c-wild-sand;
$custom-select-disabled-bg: $c-concrete;
$custom-select-bg-size: 10px 10px;
$custom-select-border-color: $c-chatelle;
$custom-select-focus-border-color: $c-black;
$custom-select-focus-box-shadow: 0 0 0;
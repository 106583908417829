﻿@import "../_variables.scss";
@import "../_mixins.scss";

a.call-to-action-block {
    text-decoration: none;

    &[class^="division--"] {
        // max-width: 430px;

        @include phone {
            align-self: center;
            width: 100%;
        }
    }

	&:hover,
    &:active {
		text-decoration: none;
		color: $c-mineshaft;
        background-color: $c-gray-hover;
	}
}

.call-to-action-block {
    position: relative;
    @include display-flex;
    @include flex-flow(row, nowrap);
    justify-content: flex-start;
    align-items: stretch;
    max-width: 100%;
    overflow: hidden;
    margin: 0 0 40px;
    color: $c-mineshaft;
    background-color: $c-gray;
    transition: background-color 0.2s ease-in-out;

    @include desktop {
        max-width: 683px;
    }

    &[class^="division--"] {
        flex-direction: column;
    }

    &.minimal {
        height: 140px;
    }

    .block-list &,
    .news-list & {
        margin-bottom: 0;
    }

    .page-sidebar & {
        @include flex-flow(column, nowrap);
        height: auto;
    }

    @include tablet {
        @include flex-flow(row, nowrap);
        justify-content: space-between;
    }

    @include phone-narrow {
        @include flex-flow(column-reverse, nowrap);
        height: auto;
    }

    > .image {
        @include flex-item(0,0,auto);
        width: 204px;
        height: 250px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .page-sidebar & {
            width: 100%;
            max-width: 100%;
        }

        @include tablet {
            @include flex-item(0, 0, auto);
        }

        @include phone-narrow {
            width: 100%;
            max-width: none;
        }
    }

    &[class^="division--"] > .image {
        width: 100%;
        height: 0;
        padding-bottom: 70%;

        @include tablet {
            @include flex-item(1, 0, auto);
        }
    }

    &.contain-image > .image {
        background-size: contain;
    }

    & > .call-to-action-text {
        @include flex-item(1, 1, auto);
    }

    .call-to-action-text {
        position: relative;
        display: block;
        max-height: 170px;
        overflow: hidden;
        margin: 40px;
        margin-right: 70px;

        .startpage & {
            margin: 0;
        }

        .col-lg-4 & {
            max-height: 250px;
            margin: 0;
            padding: 40px;
            padding-right: 70px;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 40px;
            background: linear-gradient(transparent, $c-gray-hover);
            opacity: 0;
            transition: opacity 0.15s ease-in-out;

            .startpage & {
                display: none;
            }

            @media (hover: none) {
                display: none;
            }

            @include phone {
                display: none;
            }
        }

        .col-33 &,
        .col-md-4 & {
            margin: 20px;
        }

        @include tablet {
            height: auto !important;
        }

        @include phone {
            max-width: none;
            margin: 20px;
        }

        @include phone-narrow {
            width: 100%;
            max-width: none;
        }

        .page-sidebar & {
            max-width: 100%;
        }

        h3 {
            margin: 0;
            font-family: $font-family-medium;
            font-size: 1.125rem;
        }

        &-hover {
            margin-top: 1rem;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;

            .startpage & {
                display: none;
            }

            @media (hover: none) {
                display: none;
            }

            @include phone {
                display: none;
            }
        }

        .cta {
            &-text {
                @include flex-item(0,0,auto);
                align-self: flex-start;
                max-width: 100%;
                font-size: 1rem;
                font-weight: 500;

                .page-sidebar &,
                .col-md-4 &,
                .col-33 &,
                .col-25 & {
                    align-self: flex-end;

                    @include tablet {
                        position: relative;
                        align-self: flex-start;
                        padding-right: 2rem;

                        &:after {
                            position: absolute;
                            bottom: 0.25rem;
                            right: 0;
                        }
                    }

                    @include phone {
                        position: relative;
                        align-self: flex-start;
                        padding-right: 2rem;

                        &:after {
                            position: absolute;
                            bottom: 0.25rem;
                            right: 0;
                        }
                    }

                    @include phone-narrow {
                        padding-top: 0.5rem;
                    }
                }

                @include phone {
                    align-self: flex-end;
                    @include flex-item(0,0,auto);
                    margin-top: 1rem;
                }

                & > .link-text {
                    .col-md-4 &,
                    .col-33 &,
                    .col-25 & {
                        display: none;

                        @include tablet {
                            display: inline-block;
                        }
                    }

                    .page-sidebar & {
                        font-size: 1rem;
                    }
                }

                & > .elk-icon {
                    display: inline-block;
                    padding-left: 1em;

                    @include phone {
                        position: absolute;
                        bottom: 1.5rem;
                        right: 1.5rem;
                        font-size: 1.55556rem;
                        font-weight: 500;
                    }

                    .col-md-4 &,
                    .col-33 &,
                    .col-25 & {
                        position: absolute;
                        bottom: 1.5rem;
                        right: 1.5rem;
                        font-size: 1.55556rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    &[class^="division--"] .call-to-action-text {
        padding: 0;
        border-bottom: 6px solid $c-corporate;

        h2,
        h3 {
            margin-bottom: 0;
            padding: 24px 18px;
            font-family: $font-family-book;
            font-size: 1.5rem;
        }

        @include large-desktop {
            h2 {
                font-size: 1.75rem;
            }
        }
    }

    @include phone {
        .image {
            img {
                height: 180px;
                width: 160px;
            }
        }
    }

    .call-to-action-arrow {
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 72px;
        height: 72px;
        background-color: $c-corporate;
        overflow: hidden;

        @include phone {
            top: 178px;
            bottom: auto;        
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 72px;
            height: 72px;
            background: transparent url('/Static/img/icons/elk-arrow-right-white.svg') center center / 28px no-repeat;
            transition: left 0.2s ease-in-out;
        }
        &::before {
            left: -72px;
        }
    }

    &[class^="division--"] {
        @include phone {
            .call-to-action-arrow {
                top: auto;
                bottom: 0;
            }
        }
    }

    &:hover,
    &:focus {
        .call-to-action-arrow {
            &::before {
                left: 0;
            }

            &::after {
                left: 72px;
            }
        }

        .call-to-action-text::after {
            opacity: 1;
            transition-duration: 0.25s;
        }

        .call-to-action-text-hover {
            opacity: 1;
        }
    }

    &.division--silicones {
        &::after {
            background-color: $c-silicones;
        }

        .call-to-action-text {
            border-color: $c-silicones;
        }
    }

    &.division--silicon-products {
        &::after {
            background-color: $c-silicon-products;
        }

        .call-to-action-text {
            border-color: $c-silicon-products;
        }
    }

    &.division--carbon-solutions {
        &::after {
            background-color: $c-carbon;
        }

        .call-to-action-text {
            border-color: $c-carbon;
        }
    }

    &.division--corporate {
        &::after {
            background-color: $c-corporate;
        }

        .call-to-action-text {
            border-color: $c-corporate;
        }
    }
}
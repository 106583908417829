﻿@import "../_variables.scss";
@import "../_mixins.scss";

.contact {
    &-block {
        @include flex();
        max-width: 100%;
        justify-content: space-between;
        column-gap: 3em;
        row-gap: 1em;
        background: $c-gray;

        .page-sidebar & {
            background: $c-gray;
            padding: 24px;
            font-size: 1rem;
        }

        &-wrapper {
            margin-top: 2rem;
            margin-bottom: 2rem;
            background: $c-gray;
    
            .contact-block {
                max-width: 1280px;
                margin-right: auto;
                margin-left: auto;
        
                img {
                    width: 100%;
                    height: auto;
        
                    @include tablet-up {
                        width: auto;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        section:first-child {
            width: 40%;
            padding: 2em 0;
        }

        section:last-child {
            width: 50%;
        }

        @include desktop {
            padding: 38px 30px 38px 84px;

            img {
                width: 100%;
            }
        }

        @include tablet {
            padding: 24px;
        }

        @include phone {
            padding: 24px;

            section:first-child,
            section:last-child {
                width: 100%;
                padding: 0;
            }
        }

        h4 {
            font-size: $block-heading-font-size;
            margin-bottom: $paragraph-spacing;
            margin-top: .2em;

            .page-sidebar & {
                font-size: 1.25rem;
            }
        }

        a {
            &[href^="mailto:"] {
                word-break: break-word;
            }

            &:hover {
                text-decoration: underline !important;
                color: $c-corporate !important;
            }
        }

        .no-bullets {
            padding-top: 0.75em;

            li {
                position: relative;
                padding: .75em .15rem .75em .3rem;
                font-weight: 500;
                border-bottom: 1px solid black;

                &:first-child {
                    border-top: 1px solid black;
                }

                + li {
                    .article-text .body & {
                        margin-top: 0;
                    }
                }

                .flex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 0.2em;
                }
            }

            .article-text .body & {
                a {
                    padding-right: 0;
                    padding-left: 0;
                    background-color: transparent;
                    border-bottom: none;
                }
            }
        }
    }
}

@import "../_variables";

.cta-banner {
    margin-top: 2rem;
    margin-bottom: 4rem;

    @include tablet-up {
        display: flex;
        flex-direction: row;
        margin-top: 4rem;
        background-color: $c-elkem-grey;
    }

    &__image {
        display: flex;
        justify-content: center;
        padding-right: 20px;
        padding-left: 20px;

        @include phone {
            padding-top: 20px;
        }

        @include tablet-up {
            flex-basis: 33.333%;
            flex-shrink: 0;
            justify-content: flex-end;
        }

        img {
            width: auto;
            max-width: 250px;
            height: auto;
            max-height: 250px;
            margin-bottom: -20px;

            @include tablet-up {
                margin-top: -20px;
            }
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-basis: 66.666%;

        @include phone {
            padding-top: 20px;
            background-color: $c-elkem-grey;
        }

        @include tablet-up {
            flex-grow: 0;
        }

        &-inner {
            padding: 20px;

            @include tablet-up {
                max-width: 350px;
            }
        }

        .cta-banner__title {
            margin-top: 0;
            margin-bottom: .25rem;
            font-size: 1.25em;
            font-weight: 600;
        }

        p {
            margin-top: 0;
            margin-bottom: .5rem;
            line-height: 1.3;
        }
    }

    &,
    .article-text .abstract &,
    .article-text .body & {
        .cta-banner__action {
            a {
                display: inline-block;
                padding: 10px 12px;
                font-family: $font-family-medium;
                color: $c-white;
                background-color: $c-corporate;
                border-bottom: 0;
    
                &:hover,
                &:focus {
                    text-decoration: none;
                    background-color: $c-corporate-darker;
                }
            }
    
            &.division--silicones a {
                background-color: $c-silicones;
    
                &:hover,
                &:focus {
                    background-color: $c-silicones-darker;
                }
            }
    
            &.division--silicon-products a {
                background-color: $c-silicon-products;
    
                &:hover,
                &:focus {
                    background-color: $c-silicon-products-darker;
                }
            }
    
            &.division--carbon-solutions a {
                background-color: $c-carbon;
    
                &:hover,
                &:focus {
                    background-color: $c-carbon-darker;
                }
            }
    
            &.division--corporate a {
                background-color: $c-corporate;
    
                &:hover,
                &:focus {
                    background-color: $c-corporate-darker;
                }
            }
        }
    }
}
.dropdown-list-page-block {
    max-width: $max-content-width;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 1.25rem 20px;
}

.dropdown-list-item {
    margin-top: -1px;

    &__heading {
        margin-top: 2rem;
        margin-bottom: 1rem;

        @include desktop {
            margin-top: 4rem;
            margin-bottom: 2rem;
            font-size: 1.75rem;
        }
    }

    &__content {
        .intro,
        .text {
            max-width: 660px;
        }

        .images {
            display: flex;
            gap: 20px;
            margin-bottom: 2rem;

            figure {
                width: calc(50% - 10px);
                margin-bottom: .5rem;

                img {
                    width: 100%;
                }
            }

            @include phone-narrow {
                flex-direction: column;

                figure {
                    width: 100%;
                }
            }

            figcaption {
                border-bottom: none;
            }
        }

        .intro {
            margin-top: 1rem;
            font-size: 1.125rem;
            font-weight: 700;

            @include phone {
                font-size: 1rem;
            }
        }

        .call-to-action {
            a {
                display: inline-block;
                margin-bottom: 1rem;

                &:not(:last-child) {
                    margin-right: 15px;
                }

                &.btn {
                    min-width: 0;
                    padding-right: 15px;
                    padding-left: 15px;
                    font-size: 1rem;

                    @include phone-narrow {
                        display: block;
                        margin-right: 0;
                        text-align: center;
                    }
                }
            }
        }

        @include desktop {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: minmax(20px, auto) minmax(20px, auto) 1fr;
            gap: 0px 30px;
            grid-auto-flow: row;
            grid-template-areas:
                "intro images"
                "text images"
                "cta images";
            .intro {
                grid-area: intro;
                font-size: 1.125rem;
            }
            .text { grid-area: text; }
            .call-to-action { grid-area: cta; }
            .images {
                grid-area: images;
                margin-top: 1rem;
            }
        }

        @include tablet {
            display: flex;
            flex-direction: column;

            .intro {
                order: 1;
            }

            .text {
                order: 2;
            }

            .images {
                order: 3;
            }

            .call-to-action {
                order: 4;
            }
        }
    }

    details {
        border-top: 1px solid $c-ash;
        border-bottom: 1px solid $c-ash;

        summary {
            padding-top: 1rem;
            padding-bottom: 1rem;
            list-style: none;

            &::marker,
            &::-webkit-details-marker {
                display: none;
            }

            h3 {
                position: relative;
                margin-bottom: 0;
                padding-right: 50px;
                font-size: 1.375rem;
                color: $c-charcoal;

                @include tablet {
                    padding-right: 30px;
                    font-size: 1.125rem;
                }

                span.icon {
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    width: 24px;
                    height: 24px;
                    transition: transform .2s ease-in-out;

                    @include tablet {
                        right: 0;
                    }
                }

                &:hover {
                    color: $c-corporate;

                    span.icon {
                        svg path {
                            fill: $c-corporate;
                        }
                    }
                }
            }
        }

        &[open] {
            padding-bottom: 2rem;

            summary {
                h3 {
                    span.icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
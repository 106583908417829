.marketpage {
    margin-right: -15px;
    margin-left: -15px;

    @include tablet {
        margin-right: -60px;
        margin-left: -60px;
    }

    @include phone {
        margin-right: -24px;
        margin-left: -24px;
    }

    &__header {
        max-width: 950px;
        margin-right: auto;
        margin-left: auto;
        padding: 2.5rem 15px 2rem;
    
        @include phone {
            padding-top: 1.5rem;
        }
    }

    h1 {
        margin-bottom: 1.5rem;
        font-family: $font-family-book;
        font-size: 2.5rem;
        color: $c-corporate;

        @include tablet {
            font-size: 1.75rem;
        }

        @include phone {
            font-size: 1.5rem;
        }
    }

    .intro {
        font-size: 1.5rem;

        @include tablet {
            font-size: 1.25rem;
        }

        @include phone {
            font-size: 1.125rem;
        }
    }

    .tags {
        margin-top: 2.5rem;
    }

    @include tablet {
        .container.stripe {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.marketsubpage {
        .tagline {
            margin-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: 700;

            @include tablet {
                font-size: 1.25rem;
            }

            @include phone {
                font-size: 1.125rem;
            }
        }
    }
}
.image-block {
    &-fullwidth {
        position: relative;
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include desktop {
            z-index: 1;
            display: flex;
            gap: 30px;
            margin-right: -15%;
            margin-left: -15%;

            .image-block-image {
                width: 100%;

                &:has(+ .image-block-figcaption) {
                    flex-basis: calc(75% - 30px);
                    flex-shrink: 0;
                    width: calc(75% - 30px);
                }
            }

            .image-block-figcaption {
                flex-basis: 25%;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }

        @include phone {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    .article-body & {
        &.float-right,
        &.float-left {
            max-width: 60%;
        }
    }

    &-image {
        margin-bottom: 0.5rem;
    }

    &-credits {
        font-size: 0.75rem;

        .article-text .body & {
            ul li {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }

        .byline {
            margin-bottom: 0.25rem;

            &-name {
                color: $c-black;
            }
        }
    }
}
.division--carbon-solutions {
	.site-header {
		&--theme::after {
			background-color: $c-carbon;
		}
	
		.site-logo-nav::after {
			@include tablet {
				background-color: $c-carbon;
			}
		}
	}

	.block-highlight-numbers {
		border-left-color: $c-carbon;
	}

	.site-footer {
		border-top-color: $c-carbon;
	}

	.division-intro__image {
		background-color: $c-carbon;
	}
	
	&.call-to-action-block .call-to-action-arrow {
		background-color: $c-carbon;
	}
}
﻿/* Classes EPiServer.Forms randers the forms with */

/* <form> */
.EPiServerForms {

}

.Form {

    /* <h2> */
    &__Title {

    }

    /* <div> */
    &__MainBody {

    }

    /* <div> */
    &__Element {

        /* <label> */
        &__Caption {

        }

        /* <span> */
        &__ValidationError {

        }

    }

}

/* All 3 of those are used together with Form__Element */

/* <div>, contains <select> */
.FormSelection {
}

/* <div>, contains <input> */
.FormTextbox {

    /* <input> */
    &__Input {

    }

}

/* <button> */
.FormSubmitButton {

}

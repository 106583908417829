.start-page-top-block {
    margin-right: -15px;
    margin-left: -15px;

    @include tablet {
        margin-right: -60px;
        margin-left: -60px;
    }

    @include phone {
        margin-right: -24px;
        margin-left: -24px;
    }

    .heading {
        h1 {
            margin-bottom: 1rem;
        }

        @include desktop {
            h1 {
                font-size: 2rem;
            }
        }

        @include large-desktop {
            h1 {
                font-size: 2.625rem;
            }
        }

        @include xlarge-desktop {
            h1 {
                font-size: 3vw;
            }
        }

        &.smaller-text h1 {
            font-size: 2rem;
        }
    }

    .text {
        max-width: 640px;
    }

    .text,
    .links {
        font-size: 1.25rem;

        @include phone {
            font-size: 1rem;
        }
    }

    .links {
        max-width: 220px;
        margin-top: 2rem;

        .link {
            display: flex;
            align-items: flex-start;
            gap: 30px;
            padding: .5rem 0;
            color: $c-black;
            border-bottom: 1px solid $c-black;

            @include phone {
                gap: 20px;
            }

            &__icon {
                flex-basis: 24px;
            }

            + .link {
                margin-top: 1rem;
            }

            &:hover {
                text-underline-offset: .25rem;
            }
        }
    }

    &.one-image {
        .inner {
            position: relative;

            .image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0,0,0,0.5);
                }
            }

            .content {
                position: relative;
                padding: 140px;
                color: $c-white;

                @include desktop-down {
                    padding: 70px;
                }

                @include tablet {
                    padding: 35px;
                }
            }

            .links a {
                color: $c-white;
                border-bottom-color: $c-white;

                svg path {
                    stroke: $c-white;
                }
            }
        }

        &.text-color-black .inner {
            .image::after {
                background-color: rgba(255,255,255,0.5);
            }

            .content {
                color: $c-black;
            }

            .links a {
                color: $c-black;
                border-bottom-color: $c-black;

                svg path {
                    stroke: $c-black;
                }
            }
        }
    }

    &.multiple-images {
        .inner {
            display: flex;
            margin-right: auto;
            margin-left: auto;

            @include tablet {
                flex-direction: column;
            }
        }

        .content {
            @include tablet {
                order: 2;
                padding: 35px;
            }

            @include desktop {
                flex-basis: 45%;
                padding: 35px;
                display: flex;
                align-items: center;
            }

            @include large-desktop {
                padding: 70px;
            }
        }

        .images {
            flex-basis: 55%;

            &.three {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 60% 40%;
                grid-auto-flow: row;
                grid-template-areas:
                    "img1 img3"
                    "img2 img3";
                .image:nth-child(1) { grid-area: img1; }
                .image:nth-child(2) { grid-area: img2; }
                .image:nth-child(3) { grid-area: img3; }
            }

            &.two {
                display: flex;

                .image {
                    flex-basis: 50%;
                }
            }

            @include tablet {
                order: 1;

                &.three {
                    .image {
                        flex-basis: 33.333%;
                    }
                }

                &.two {
                    .image {
                        flex-basis: 50%;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            // Safari only hack: Safari fails in rendering image height 100% within CSS grid
            @supports (-webkit-hyphens: none) {
                overflow: hidden;

                img {
                    height: calc(100% + 2px);
                    margin-top: -1px;
                }
            }
        }

        .text,
        .links {
            font-size: 1.125rem;

            @include phone {
                font-size: 1rem;
            }
        }

        .links a svg path {
            stroke: $c-black;
        }
    }
}
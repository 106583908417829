span.elk {
    &-icon {
        display: inline-block;

        &::before {
            content: '';
            display: inline-block;
            width: 28px;
            height: 28px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }

    &-globe::before {
        background-image: url('/Static/img/icons/elk-globe.svg');
    }

    &-map-pin::before {
        background-image: url('/Static/img/icons/elk-map-pin.svg');
    }

    &-search::before {
        width: 18px;
        height: 18px;
        background-image: url('/Static/img/icons/elk-search.svg');
    }

    &-email::before {
        background-image: url('/Static/img/icons/elk-email.svg');
    }

    &-phone::before {
        background-image: url('/Static/img/icons/elk-phone.svg');
    }

    &-form::before {
        background-image: url('/Static/img/icons/elk-form.svg');
    }

    &-contact::before {
        background-image: url('/Static/img/icons/elk-form.svg');
    }

    &-facebook::before {
        background-image: url('/Static/img/icons/elk-facebook.svg');
    }

    &-twitter::before {
        background-image: url('/Static/img/icons/elk-twitter.svg');
    }

    &-youtube::before {
        background-image: url('/Static/img/icons/elk-youtube.svg');
    }

    &-linkedin::before {
        background-image: url('/Static/img/icons/elk-linkedin.svg');
    }

    &-arrow-up::before {
        background-image: url('/Static/img/icons/elk-arrow-up.svg');
    }

    &-arrow-right::before {
        background-image: url('/Static/img/icons/elk-arrow-right.svg');
    }

    &-arrow-down::before {
        background-image: url('/Static/img/icons/elk-arrow-down.svg');
    }

    &-arrow-left::before {
        background-image: url('/Static/img/icons/elk-arrow-left.svg');
    }

    &-chevron-down::before {
        background-image: url('/Static/img/icons/elk-chevron-down.svg');
    }

    &-chevron-up::before {
        background-image: url('/Static/img/icons/elk-chevron-up.svg');
    }

    &-close::before {
        background-image: url('/Static/img/icons/elk-close.svg');
    }

    &-checkmark::before {
        background-image: url('/Static/img/icons/elk-checkmark.svg');
    }

    &-checkmark-fat::before {
        background-image: url('/Static/img/icons/elk-checkmark-fat.svg');
    }

    &-menu::before {
        background-image: url('/Static/img/icons/elk-menu.svg');
    }

    &-clock::before {
        background-image: url('/Static/img/icons/elk-clock.svg');
    }

    &-calendar::before {
        background-image: url('/Static/img/icons/elk-calendar.svg');
    }

    &-plus::before {
        background-image: url('/Static/img/icons/elk-plus.svg');
    }
}

.svg-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &--market {
        position: absolute;
        left: calc(50% - 25px);//50%
        // transform: translateX(-50%);
        width: 50px;
        height: 50px;
        margin-top: -25px;
        background-color: #fff;
        border-radius: 50%;
    }
}
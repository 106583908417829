.simple-contact {
    padding: 75px 50px;
    background-color: $c-linen;

    @include tablet {
        text-align: center;
    }

    @include phone {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .article-page &,
    .start-page &,
    .contact-page & {
        margin-right: -15px;
        margin-left: -15px;

        @include tablet {
            margin-right: -60px;
            margin-left: -60px;
        }

        @include phone {
            margin-right: -24px;
            margin-left: -24px;
        }
    }

    .magazine-featured & {
        color: black;
    }

    &__heading {
        margin-bottom: 20px;
        font-family: $font-family-book;
        font-size: 1.75rem;

        @include tablet-up {
            font-size: 2.25rem;
        }
    }

    &__text {
        max-width: 650px;
        margin-right: auto;
        margin-left: auto;
        font-size: 1.25rem;
        line-height: 1.5;

        @include tablet-up {
            font-size: 1.375rem;
        }

        p {
            margin-bottom: 0;
        }
    }

    @include tablet {
        &__cta {
            margin-top: 2rem;
        }
    }

    @include desktop {
        .wrap-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;
            max-width: $max-content-width-narrow;
            margin-right: auto;
            margin-left: auto;

            .simple-contact-content {
                flex-grow: 1;
            }

            .simple-contact-cta {
                flex-shrink: 1;
            }
        }
    }
}
.magazine-article {
    display: block;
    margin-bottom: 50px;

    @include tablet {
        margin-bottom: 30px;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        .magazine-article__title {
            text-decoration: underline;
        }
    }

    &__image {
        min-width: 100%;
        margin-bottom: 1.5rem;
        height: 0;
        padding-bottom: 56.25%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @include desktop {
            .feature-image-8 & {
                padding-bottom: 52.72%;
            }

            .feature-image-7 & {
                padding-bottom: 58.05%;
            }

            .feature-image-5 & {
                padding-bottom: 82.72%;
            }

            .feature-image-4 & {
                padding-bottom: 109.5%;
            }

            .list-image & {
                padding-bottom: 56.25%;
            }

            .latest-blog-posts-list & {
                padding-bottom: 17%;
            }
        }

        @include tablet {
            margin-bottom: 1rem;

            .feature-image-8 & {
                padding-bottom: 52.78%;
            }

            .feature-image-7 &,
            .feature-image-5 &,
            .feature-image-4 &,
            .list-image & {
                padding-bottom: 64.31%;
            }

            .latest-blog-posts-list & {
                padding-bottom: 17%;
            }
        }

        @include phone {
            margin-bottom: 0.5rem;

            .feature-image-8 &,
            .feature-image-7 &,
            .feature-image-5 &,
            .feature-image-4 &,
            .list-image & {
                padding-bottom: 70.33%;
            }
        }
    }

    &__title {
        .main-article &,
        .featured-articles & {
            font-size: 2rem;

            @include tablet {
                font-size: 1.5rem;
            }
        }
    }

    &__details {
        line-height: 1;
        color: $c-ash;

        &-topic,
        &-label {
            font-family: $font-family-medium;
        }

        span {
            position: relative;
            margin-right: 3px;
            padding-right: 8px;
            font-size: 0.75rem;

            &:not(:last-child)::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 2px;
                right: 0;
                bottom: 3px;
                width: 2px;
                background-color: $c-chatelle;
            }
        }
    }

    .related & {
        color: $c-white;

        &__details {
            color: $c-chatelle;

            span {
                &:not(:last-child)::after {
                    background-color: $c-chatelle;
                }
            }
        }
    }
}
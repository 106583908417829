.related {
    margin-right: -15px;
    margin-left: -15px;
    padding: 2rem 3rem 1rem;
    background-color: $c-concrete;
    border-top: 6px solid $c-silicones;

    .startpage & {
        margin-top: 3rem;
        border-top: none;
    }

    &-list {
        margin-bottom: 4rem;
        padding-left: 24px;
        border-top: none;
        border-left: 6px solid $c-silicones;

        h2 {
            margin-bottom: 0;
            padding-bottom: 0.75rem;
            font-size: 0.75rem;
            text-transform: uppercase;
            color: $c-ash;
            border-bottom: 1px solid $c-concrete;
        }

        ul {
            margin: 0;
            padding-left: 0;
            list-style: none;

            li {
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                font-family: $font-family-medium;
                border-bottom: 1px solid $c-concrete;
            }
        }
    }

    // @include desktop {
    //     margin-bottom: -3rem;
    // }

    @include tablet {
        margin-top: 1rem;
        margin-right: -60px;
        margin-left: -60px;
        padding-right: 60px;
        padding-left: 60px;
    }

    @include phone {
        margin-right: -24px;
        margin-left: -24px;
        padding-right: 24px;
        padding-left: 24px;
    }

    + .subscription--magazine {
        margin-top: 0.375rem;
        border-top: 6px solid $c-concrete;
    }

    &--magazine {
        color: $c-white;
        background-color: $c-charcoal;

        a {
            color: $c-white;
        }
    }

    &--header {
        @include tablet-up {
            @include flex;
            @include flex-flow(row, nowrap);
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &--heading {
        margin-bottom: 1.5rem;
    }

    &--more {
        padding: 4px 50px;
        font-size: 0.875rem;
        border: 1px solid currentColor;
        border-radius: 3px;

        @include phone {
            display: inline-block;
            margin-bottom: 2rem;
        }
    }
}
.announcement-block {
    margin-right: -15px;
    margin-left: -15px;

    @include tablet {
        margin-right: -60px;
        margin-left: -60px;
    }

    @include phone {
        margin-right: -24px;
        margin-left: -24px;
    }

    &__link {
        display: block;
        padding: 15px;
        font-family: $font-family-medium;
        font-size: 1.25rem;
        text-align: center;

        @include phone {
            font-size: 1.125rem;
        }

        &.division--corporate {
            background-color: $c-corporate;
        }
    
        &.division--silicones {
            background-color: $c-silicones;
        }
    
        &.division--silicon-products {
            background-color: $c-silicon-products;
        }
    
        &.division--carbon-solutions {
            background-color: $c-carbon;
        }

        &:hover {
            color: currentColor;
        }

        .inner {
            display: inline-flex;
            gap: 15px;
            align-items: center;
        }
    }
}
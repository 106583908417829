﻿.events {
    border-top: 6px solid $c-concrete;

    &-event {
        @include display-flex;
        @include flex-direction(column);
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        text-decoration: none;
        border-bottom: 6px solid $c-concrete;

        &.past-event .events-event-name {
            color: $c-ash;
        }

        &-date + .events-event-name {
            margin-top: 0.5rem;
        }

        &-date,
        &-location {
            font-size: 0.875rem;
            color: $c-ash;
        }

        &-location {
            margin-bottom: 0.25rem;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $c-black;
            border-bottom-color: $c-corporate;
        }
    }
}

.event {
    &-location {
        margin-top: -2rem;
        margin-bottom: 4rem;
        color: $c-ash;

        @include tablet {
            margin-top: -1rem;
            margin-bottom: 3rem;
        }
    }

    &-page a {
        word-wrap: break-word;
    }
}
﻿.investor-info-subscription-block {
    margin-bottom: element-spacing-units(2);

    margin-right: -15px;
    margin-left: -15px;

    @include tablet {
        margin-right: -60px;
        margin-left: -60px;
    }

    @include phone {
        margin-right: -24px;
        margin-left: -24px;
    }

    &.filled {
        background-color: $c-gray-xlight;

        .magazine & {
            background-color: transparent;
        }
    }

    .investor-info-subscription {
        @include flex;
        @include flex-flow(row, nowrap);
        width: 100%;
        max-width: unset;

        .row {
            margin-right: -6px;
            margin-left: -6px;
        }

        [class^="col-"],
        .col {
            margin-bottom: 8px;
            padding-right: 6px;
            padding-left: 6px;
        }

        &__image {
            flex-basis: 50%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-right: 6px solid $c-carbon;
        }

        &__form {
            flex-basis: 50%;
            max-width: 613px;
            padding: 48px;

            &-heading {
                font-family: $font-family-book;
                font-size: 2rem;
            }

            &-title {
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                font-family: $font-family-book;
                font-size: 0.875rem;
            }

            &-languages {
                .elk-checkbox {
                    display: inline-block;
                    margin-right: 20px;
                }
            }

            &-two-fields {
                @include tablet-up {
                    @include flex;
                    @include flex-flow(row, nowrap);

                    .form-group {
                        flex-basis: 50%;
                        margin-bottom: 12px;
    
                        &:first-child {
                            margin-right: 5px;
                        }
    
                        &:last-child {
                            margin-left: 5px;
                        }
                    }
                }
            }

            &-information-types {
                .elk-checkbox {
                    > input {
                        + label {
                            .indicator {
                                display: none;
                            }

                            display: block;
                            position: relative;
                            width: 100%;
                            margin-bottom: 6px;
                            padding: 7px 10px 7px 48px;
                            font-size: 0.875rem;
                            background-color: $c-white;
                            border: 1px solid $c-chatelle;
                            border-radius: 2px;

                            &::after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                top: 50%;
                                right: 10px;
                                width: 12px;
                                height: 12px;
                                margin-top: -6px;
                                background: url('/Static/img/icons/elk-checkmark-white.svg') center center / contain no-repeat;
                            }

                            &::before {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                top: 50%;
                                left: 10px;
                                width: 24px;
                                height: 24px;
                                margin-top: -12px;
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                        &:checked + label {
                            color: $c-white;
                            background-color: $c-black;
                        }

                        &:focus + label {
                            border-color: $c-black;
                        }
                    }

                    &.interim > input + label::before { background-image: url('/Static/img/icons/investor-interim.svg'); }
                    &.interim > input:checked + label::before { background-image: url('/Static/img/icons/investor-interim-white.svg'); }

                    &.annual-reports > input + label::before { background-image: url('/Static/img/icons/investor-annual-reports.svg'); }
                    &.annual-reports > input:checked + label::before { background-image: url('/Static/img/icons/investor-annual-reports-white.svg'); }

                    &.press > input + label::before { background-image: url('/Static/img/icons/investor-press.svg'); }
                    &.press > input:checked + label::before { background-image: url('/Static/img/icons/investor-press-white.svg'); }

                    &.newsletter > input + label::before { background-image: url('/Static/img/icons/investor-newsletters.svg'); }
                    &.newsletter > input:checked + label::before { background-image: url('/Static/img/icons/investor-newsletters-white.svg'); }

                    &.magazine > input + label::before { background-image: url('/Static/img/icons/investor-magazine.svg'); }
                    &.magazine > input:checked + label::before { background-image: url('/Static/img/icons/investor-magazine-white.svg'); }
                }
            }
        }

        @include tablet {
            flex-direction: column;

            &__image {
                height: 0;
                padding-bottom: 60%;
                border-right: none;
                border-bottom: 6px solid $c-carbon;
            }

            &__form {
                padding: 30px;
            }
        }

        @include phone {
            &__form {
                padding: 24px;
            }
        }
    }

    h3 {
        margin-bottom: 2rem;
    }

    .form-group {
        @include flex;
        @include flex-flow(row, nowrap);
        margin-bottom: 0.3rem;

        .form-control {
            min-height: 1rem;
            padding: 0.375rem 12px;
            font-size: 0.875rem;
            color: $c-ash;
        }

        .form-control-email {
            @include flex-item(0,1,auto);
        }

        .btn-subscribe {
            @include flex-item(0,0,auto);
            width: 100%;
            padding: 0.375rem 12px;
            font-size: 0.875rem;
            color: $c-white;
            background-color: $c-corporate;
            border-radius: 2px;
        }
    }
}

.fullwidth {
    .investor-info-subscription-block {
        margin-bottom: 0;
    }
}
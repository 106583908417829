﻿@import "../variables";
@import "../mixins";

.contact-page {
    &-introtext {
        font-size: 1.5em;
        margin-bottom: 1.5em;
    }

    &-hero {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 600px;

        @include tablet {
            height: 400px;
        }

        @include phone {
            height: 200px;
        }
    }

    .facts {
        p {
            margin-bottom: 0;
        }
        ul {
            padding-left: 1.5em;
        }
    }

    .map-section {
        &-heading {
            text-transform: uppercase;
            text-align: center;
        }
    }

    // location page specific

    .body {
        margin-top: 1em;
    }

    > .fullwidth {
        @include desktop {
            margin-left: -15px;
            margin-right: -15px;
        }

        @include tablet {
            margin-left: -60px;
            margin-right: -60px;
        }

        @include phone {
            margin-left: -24px;
            margin-right: -24px;
        }

        .container {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    // end location page specific
    // country page specific
    .no-padding-bottom {
        padding-bottom: 0;
    }
    // end country page specific
}


.country-department {
    margin-bottom: 2em;

    h2 {
        font-family: $font-family-book;
    }

    details {
        padding: 1em 0 1em 0.5em;
        border-top: 1px solid $c-charcoal-light;

        @include tablet-up {
            .details-content, hr, footer {
                padding: 0 2em;
            }
        }

        summary {
            @include flex();
            align-items: center;
            line-height: 1;
            font-size: 1.25em;
            font-weight: 500;
            list-style-type: none;
            transition: color .25s ease-out;

            &::marker {
                content: "";
            }

            &::-webkit-details-marker {
                display: none;
            }

            .contact-details-toggle {
                transform: rotate(-90deg);
                opacity: 1;
                transition: transform .25s ease-out, opacity .25s ease-out;

                &::before {
                    width: 22px;
                    height: 22px;
                }
            }

            .contact-details-summary-text {
                margin-left: 0.5em;
            }
        }

        &:last-child {
            border-bottom: 1px solid $c-charcoal-light;
        }

        hr {
            margin: 2em 0;
            background-color: black;
        }

        footer {
            padding-bottom: 2em;
            text-align: right;
        }

        .btn {
            margin-left: auto;
            margin-right: -0.5em;
            background: $c-corporate;
            color: $c-white;
            border: 0;
            border-radius: 5px;
            padding: 1em 2em;

            &:hover {
                background: $c-corporate-darker;
                color: $c-white;
            }
        }

        .details-content {
            @include display-flex();
            gap: 40px;
            font-size: 1.125rem;

            @include tablet {
                flex-direction: column;
            }

            @include tablet-up {
                > div {
                    flex-basis: 50%;
                }
            }

            .info {
                flex-direction: column;
                flex-wrap: wrap;
                column-gap: 1rem;
            }

            p:last-child {
                margin-bottom: 0;
            }
        }

        .invoice-information {
            max-width: 350px;
            margin-bottom: 2rem;
            padding: .75rem 0 .75rem 5px;
            border-top: 1px solid $c-charcoal;
            border-bottom: 1px solid $c-charcoal;

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .elk-icon {
                    position: relative;
                    top: 0.125rem;

                    &::before {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        &[open] {
            summary {
                color: $c-gray-xxlight;
                margin-bottom: 2em;

                .contact-details-toggle {
                    transform: rotate(45deg);
                    opacity: 0.5;
                }
            }
        }

        address {
            @include tablet {
                width: 100%;
            }

            ul {
                padding-top: 0.75em;
                padding-left: 0;
                list-style-type: none;
                max-width: 350px;

                @include tablet-up {
                    width: 350px;
                }

                li {
                    position: relative;
                    padding: 1em 0.15rem 1em 0.3rem;
                    font-weight: 500;
                    border-bottom: 1px solid black;

                    &:first-child {
                        border-top: 1px solid black;
                    }

                    .flex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 0.2em;
                    }

                    a {
                        padding-right: 3rem;

                        &::after {
                            font-family: "elk-icons";
                            content: unicode(\e911);
                            line-height: 1;
                            position: absolute;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}


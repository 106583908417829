﻿@import "../_variables.scss";
@import "../_mixins.scss";

.topic {
	background-color: #E6EFF6;
	color: #1E69A3;
	padding: 1rem;
	cursor: pointer;

	& > h3 {
		margin: 0;
	}

	&-wrapper{
		margin-bottom: 1rem;
	}

	&-section {

		&-contents {
			padding: 0;
		}

		&-wrapper {
			padding: 0.5rem 1rem;

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				border-bottom: 1px solid $c-mineshaft;
			}
		}

		&-index {
			list-style-type: none;
			margin: 1rem 0;
			padding: 0 0.25rem;
			@include display-flex;
			@include flex-flow(row, wrap);
			justify-content: flex-start;
			align-items: center;
			border-top: 1px solid $c-gray-light;
			border-bottom: 1px solid $c-gray-light;

			& > li {
				padding: 0.5rem 2rem 0.5rem 0;

				&:first-of-type {
					padding-left: 0;
				}

				& > a{
					color: $c-blue;
				}
			}
		}

		&-title {
			position: relative;
			padding: 1rem 0;
			cursor: pointer;

			& .arrow {
				position: absolute;
				right: 1rem;

				@extend .elk-icon;
				@extend .elk-arrow-up;
			}

			&.collapsed {

				& .arrow {

					@extend .elk-icon;
					@extend .elk-arrow-down;
				}
			}
		}
	}
}

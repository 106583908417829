@import "../_variables.scss";
@import "../_mixins.scss";
@import "../_fonts.scss";

.content-block {
    @include display-flex;
    @include flex-direction(column);
    margin-bottom: element-spacing-units(2);
    background-color: $c-gray;

    @include phone {
        margin-bottom: element-spacing-units(1);
    }

    .page-sidebar & {
        background-color: transparent;
    }

    [class^="col-md-"] & {
        margin-bottom: 30px;
    }

    .block-list &,
    .news-list & {
        @include flex-item(1,0,auto);
        margin-bottom: 0;
    }

    .text {
        @include flex-item(0,0,auto);
        padding: 20px;

        .page-sidebar & {
            padding: 0;
        }

        @include desktop {
            .col-50 & {
                padding: 40px;
            }
        }

        a {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: underline;

            svg {
                width: 16px;
                height: 16px;
                margin-right: 10px;
                margin-bottom: 0.25rem;
            }

            &:hover,
            &:focus {
                color: $c-corporate;

                svg path {
                    stroke: $c-corporate;
                }
            }

            &.no-arrow {
                font-size: 1.3rem;

                &::before {
                    display: none;
                }
            }
        }

        .block-list &,
        .news-list & {
            @include display-flex;
            @include flex-flow(column, nowrap);
            justify-content: space-between;

            & > h2,
            & > h3,
            & > h4,
            & > h5 {
                @include flex-item(0,0,auto);
            }

            & > .cta {
                @include flex-item(0,0,auto);
                align-self: flex-start;
                text-decoration: underline;
            }
        }

        & > .cta {
            .foundry & {
                color: $c-red;
            }
        }

        .page-sidebar & {
            font-size: 0.875rem;
        }
    }

    & > a {
        text-decoration: none;
        color: inherit;

        &:hover {
            text-decoration: none;
            color: inherit;

            .link-text {
                text-decoration: underline;
            }
        }            
    }

    &-heading {
        border-bottom: 6px solid $c-corporate;

        h3,
        h4 {
            margin-bottom: 0;
            padding: 24px 18px;
        }
    }

    h3,
    h4 {
        font-size: 1.125rem;
    }

    p {
        margin-bottom: 0.25rem;
    }

    .image {
        background-color: $c-chatelle;
        background-size: cover;
        background-position: center;
        height: 0;
        padding-bottom: 60%;

        &-wrap {
            @include flex-item(0,0,auto);

            .page-sidebar & {
                + .text {
                    margin-top: 1rem;
                }
            }
        }

        + .text .block-text-wrapper .block-text {
            @include desktop {
                font-size: 1.125rem;
            }

            a:not(:hover) {
                text-decoration: none;
            }
        }
    }

    .block-text-wrapper {
        margin-bottom: 1rem;

        @include phone {
            .block-text {
                height: auto !important;
            }
        }
    }

    .epi-editContainer {
        /* hack for non-functioning ellipsis js in editmode*/
        overflow-y: auto;
        max-height: 200px;
    }
}

a.content-block {
    .title {
        text-decoration: underline;
    }

    &:hover {
        text-decoration: none;
        color: $c-black;

        .title {
            color: $c-corporate;
        }
    }
}
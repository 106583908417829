.block-stripes {
    .stripe {
        display: flex;
        flex-direction: column;

        .startpage & {
            margin-right: -15px;
            margin-left: -15px;
    
            @include tablet {
                margin-right: -60px;
                margin-left: -60px;
            }
    
            @include phone {
                margin-right: -24px;
                margin-left: -24px;
            }
        }

        > .row {
            width: 100%;
        }

        @include tablet-up {
            > div {
                &:not(.row) {
                    padding-top: 2.25rem;
                    padding-bottom: 2.25rem;
                }

                > .col-md-12 > div {
                    margin-top: 2.25rem;
                    margin-bottom: 2.25rem;
                }
            }
        }

        @include desktop {
            &--odd {
                background-color: $c-elkem-grey;
            }

            &--even {
                background-color: $c-white;
            }

            &.container {
                padding-right: 0;
                padding-left: 0;

                > .row {
                    gap: 20px;
                    padding-right: 15px;
                    padding-left: 15px;

                    > .col-md-12 {
                        flex-basis: calc(50% - 10px);
                        padding-right: 0;
                        padding-left: 0;
                    }
                }

                &.fullwidth {
                    > .row > .col-md-12 {
                        flex-basis: 100%;
                    }
                }
            }
        }

        @include tablet {
            &-mobile--odd {
                background-color: $c-elkem-grey;
            }

            &-mobile--even {
                background-color: $c-white;
            }

            > .row .quote {
                margin-right: -15px;
                margin-left: -15px;
            }
        }

        @include phone {
            > div:not(.row):not(.quote):not(.simple-contact) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            .col-md-12 {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }

    @include tablet-up {
        .fullwidth .stripe {
            .quote,
            .simple-contact {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .contentareas > .container {
        max-width: 100%;

        > .row {
            max-width: $max-content-width;
            margin-right: auto;
            margin-left: auto;
        }
    }
}
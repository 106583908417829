﻿.products-list {

    @include display-flex;
    @include flex-direction(column);
    margin-bottom: element-spacing-units(1);
    background-color: $c-gray;

    ul {
        list-style-type: none;
    }

    &-headers {

        @include flex-item(1,0,auto);
        padding: 1rem 2rem;
        background-color: $c-gray;
        line-height: 1.5;

        li {
            padding: 0.5rem 0rem 0rem 0rem;
        }

        a {
            position: relative;
            display: inline-block;
            font-weight: 500;
            font-size: 1.2rem;
        }

    }

    &-products {

        padding: 0.5rem 0rem 0.5rem 2rem;

        a {
            position: relative;
            display: inline-block;
            font-weight: normal;
            font-size: 1rem;
        }
    }
}

.table-two-cols {
    &,
    &.body {
        max-width: 920px;
        margin: 2rem auto;

        @include tablet-up {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
    }

    &__items {
        @include phone {
            margin-right: -24px;
            margin-left: -24px;
        }

        > div {
            display: flex;

            @include tablet {
                flex-direction: column;
            }

            @include tablet-up {
                gap: 30px;
            }

            &:nth-child(odd) {
                background-color: $c-linen;
            }

            &:has(.EPiServerForms) {
                background-color: #f8f9fa !important;

                .EPiServerForms {
                    padding: 0;
                }
            }

            .item {
                padding: 15px;

                @include phone {
                    padding-right: 24px;
                    padding-left: 24px;
                }

                &--left {
                    font-family: $font-family-medium;
                }

                @include tablet {
                    &--left {
                        padding-bottom: 7px;
                    }

                    &--right {
                        padding-top: 7px;
                    }
                }

                @include tablet-up {
                    &--left {
                        flex-basis: 25%;
                    }

                    &--right {
                        flex-basis: calc(75% - 30px);
                    }
                }

                *:last-child {
                    margin-bottom: 0;
                }

                a {
                    padding-right: 0;
                    padding-left: 0;
                    text-decoration: underline;
                    color: $c-corporate;
                    background-color: transparent;
                    border-bottom: none;

                    &:hover {
                        color: black;
                    }
                }
            }
        }
    }
}

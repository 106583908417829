﻿.vacancy {
    &-wrapper {
        @include desktop {
            padding-top: 1.5rem;
            border-top: 6px solid $c-concrete;
        }
    }

    &-heading {
        margin-bottom: 1.5rem;
        font-size: 2rem;

        @include phone {
            font-size: 1.5rem;
        }
    }

    &-filters {
        margin-bottom: 60px;

        @include tablet {
            display: flex;
            flex-direction: row;
            margin: 0 -3px 20px;
        }

        @include phone {
            flex-flow: wrap;
        }

        label {
            font-size: 0.875rem;
        }

        select {
            min-width: 100%;
            font-family: inherit;
            font-size: 0.875rem;
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"%3E%3Cpath d="M16 18.811l-11.6-11.046c-0.124-0.162-0.317-0.265-0.534-0.265s-0.41 0.103-0.532 0.263l-0.001 0.002-2.066 1.984c-0.163 0.121-0.267 0.313-0.267 0.529s0.104 0.408 0.265 0.528l0.002 0.001 14.2 13.5c0.142 0.123 0.329 0.198 0.533 0.198s0.391-0.075 0.534-0.199l-0.001 0.001 14.2-13.495c0.163-0.121 0.267-0.313 0.267-0.529s-0.104-0.408-0.265-0.528l-0.002-0.001-2.066-1.989c-0.124-0.162-0.317-0.265-0.534-0.265s-0.41 0.103-0.532 0.263l-0.001 0.002z"/%3E%3C/svg%3E');
        }
    }

    &-filter {
        margin-bottom: 2rem;

        @include tablet {
            flex-basis: 33.33333%;
            margin-bottom: 0.25rem;
            padding-right: 3px;
            padding-left: 3px;
        }

        @include phone {
            flex-basis: 50%;
            max-width: 50%;
            margin-bottom: 0.5rem;
        }

        label {
            margin-bottom: 0.25rem;
            color: $c-ash;
        }
    }

    &-button {
        margin: 40px 0px 20px 0px;
        font-weight: bold;
        @include user-select(none);

        a {
            border: solid 1px $c-mineshaft;
            border-radius: 15px;
            padding: 20px;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: $c-mineshaft;
            }
        }
    }

    &-list {
        &-item {
            display: block;
            position: relative;
            text-decoration: none;
            padding: 1.5rem 0;

            &::after {
                content: '';
                display: block;
                position: absolute;
                right: 15px;
                bottom: 0;
                left: 15px;
                height: 6px;
                border-bottom: 6px solid $c-concrete;
            }

            &:first-child {
                @include desktop {
                    padding-top: 0;
                }
    
                @include tablet {
                    border-top: 6px solid $c-concrete;
                }
            }

            &:hover {
                text-decoration: none;
                color: $c-black;

                &::after {
                    border-bottom-color: $c-corporate;
                }

                .vacancy-list-header {
                    text-decoration: underline;
                }
            }
        }

        &-header {
            padding-bottom: 0.5rem;
            font-family: $font-family-medium;
            font-size: 1rem;
        }

        &-details {
            font-size: 0.875rem;
            color: $c-ash;

            > span:not(:last-child) {
                margin-right: 10px;

                &:after {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    position: relative;
                    top: -1px;
                    margin-left: 10px;
                    background-color: $c-ash;
                }
            }
        }

        &-more {
            &-container {
                overflow: hidden;
                height: 0px;
            }
        }
    }

    &-text {
        & > p {
            font-size: 1rem !important;

            span,
            em,
            strong{
                font-size: 1rem !important;
            }

            &:first-of-type {
                font-size: 1.2223rem !important;

                span,
                em,
                strong{
                    font-size: 1.2223rem !important;
                    font-weight: 300 !important;
                }

                &:empty {
                    display: none;
                }
            }
        }
    }
}
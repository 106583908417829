.quote {
    padding: 100px 50px;
    font-size: 3rem;
    text-align: center;

    .startpage & {
        margin-right: -15px;
        margin-left: -15px;

        @include tablet {
            margin-right: -60px;
            margin-left: -60px;
        }

        @include phone {
            margin-right: -24px;
            margin-left: -24px;
        }
    }

    @include tablet {
        padding-top: 75px;
        padding-bottom: 75px;
        font-size: 2.25rem;
    }

    @include phone {
        padding-top: 50px;
        padding-bottom: 50px;
        font-size: 1.75rem;
    }

    .body & {
        margin-bottom: 2rem;
        padding: 40px 20px;
        font-size: 2rem;
    }

    &__text {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
    }

    &.text-color-white {
        color: $c-white;
    }

    &.text-color-black {
        color: $c-black;
    }

    &.division--corporate {
        background-color: $c-corporate;
    }

    &.division--silicones {
        background-color: $c-silicones;
    }

    &.division--silicon-products {
        background-color: $c-silicon-products;
    }

    &.division--carbon-solutions {
        background-color: $c-carbon;
    }
}
@import "../_variables";

.cta-content-block {
    display: flex;
    gap: 20px;
    background-color: $c-charcoal;

    @include phone {
        flex-direction: column;
    }

    @include tablet-up {
        flex-direction: row;
        max-height: 420px;
        overflow: hidden;
    }

    .article-page &,
    .start-page & {
        margin-right: -15px;
        margin-left: -15px;

        @include tablet {
            margin-right: -60px;
            margin-left: -60px;
        }

        @include phone {
            margin-right: -24px;
            margin-left: -24px;
        }
    }

    .col-lg-6 & {
        @include phone {
            flex-direction: column;
        }

        @include desktop {
            .cta-content-block__text {
                flex-basis: 100%;
            }
        }
    }

    &,
    .btn--secondary {
        color: $c-white;
    }

    .btn--secondary:hover {
        border-color: $c-white;
        outline: 1px solid $c-white;
    }

    .article-text .body & {
        &,
        .btn--secondary {
            color: $c-white;
        }

        .btn--secondary:hover {
            border-color: $c-white;
            outline: 1px solid $c-white;
        }
    }

    .startpage & {
        .container {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    body:not(.loaded) {
        visibility: hidden;
    }

    .article-text .body & {
        margin-bottom: 2rem;
    }

    .start-page & {
        margin-right: -15px;
        margin-left: -15px;

        @include tablet {
            margin-right: -60px;
            margin-left: -60px;
        }

        @include phone {
            margin-right: -24px;
            margin-left: -24px;
        }
    }

    &__image {
        display: flex;

        @include tablet-up {
            justify-content: flex-end;
            align-items: center;
        }

        img {
            width: 100%;

            @include tablet-up {
                height: auto;
                object-fit: cover;
            }
        }
    }

    @include desktop {
        .col-lg-6 & {
            .cta-content-block__image {
                display: none;
            }
        }
    }

    &__text,
    &__image {
        flex-basis: calc(50% - 10px);
    }

    &__text {
        padding: 20px;
        max-width: 800px;

        h2 {
            font-family: $font-family-book;
            font-size: 1.5rem;
        }

        @include phone {
            padding-bottom: 45px;
        }
    }

    &__image img {
        height: 100%;
        object-fit: cover;
    }

    @include tablet-up {
        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem 30px;
        }
    }

    @include desktop {
        &__text {
            padding: 4rem 66px;

            .col-lg-8 & {
                padding: 2rem 40px 2rem 30px;
            }

            h2 {
                margin-bottom: 1rem;
                font-size: 1.75rem;
            }

            p {
                margin-bottom: 2rem;
                font-size: 1.125rem;
            }
        }
    }
}
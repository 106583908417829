﻿.optimizely-contact-form {
    .article {
        padding-right: 0 !important;
    }

    .contentarea-extended {
        margin-top: 38px !important;
    }

    .btnPrev, .btnNext, .FormSubmitButton {
        background: $c-corporate !important;
        color: $c-white !important;
        font-family: system-ui, -apple-system !important;
        border-radius: 4px !important;
        text-align: center !important;
        align-items: center !important;
        padding: 9px 12px !important;
        font-size: 1rem !important;
        border: 0 !important;
        overflow: hidden;

        &:hover{
            background: $c-corporate !important;
            color:$c-white !important;
        }

        &::after{
            filter:invert(1);
        }

        &::before{
            filter:invert(1);
        }
    }

    .business-form {
        min-height: 785px;
    }

    .EPiServerForms .FormChoice--Image,
    & {
        .FormChoice--Image__Item {
            display: inline-flex !important;
            justify-content: space-between;
            text-align: left;
            margin: 0.625rem 0.5rem;
            align-items: center;
            gap: 11px;
            padding: 10px 15px;
            background: white;
            border: 1px solid $gray-400 !important;
            width: 248px !important;
            max-width: inherit !important;
            border-radius: 4px;
            cursor: pointer;

            img {
                border: none !important;
            }
        }
    }

    input[type=text],
    select,
    input[type=number],
    input[type=email] {
        border: 1px solid $gray-400 !important;
        border-radius: 0.25rem !important;
        padding: .375rem 2.25rem .375rem .75rem !important;
        font-size: 1rem;
        line-height: 1.5;
    }

    label,
    legend {
        font-size: 1rem !important;
        color:$gray-900 !important;
    }

    textarea {
        min-height: 250px !important;
        border-radius: 4px !important;
        border: 1px solid $gray-400 !important;

    }

    select {
        cursor: pointer;
    }

    input::placeholder {
        font-size: 0.8rem;
        color: #778c9d;
    }

    .abstract p a {
        background: $c-corporate;
        color: $c-white;
        font-family: system-ui, -apple-system;
        border-radius: 4px;
        text-align: center;
        align-items: center;
        padding: 9px 23px;
        font-size: 1rem;
        border-bottom: 0;

        &:hover {
            background: $c-corporate;
            color:$c-white;
        }
    }
}

.article {
    &-content {
        .article-page & {
            margin-bottom: $element-spacing-unit;
        }

        h1 {
            margin-top: 0;

            @include tablet {
                margin-bottom: 1.5rem;
            }

            .magazine & {
                font-family: $font-family-medium;
            }
        }

        .colophon {
            margin-bottom: 1rem;
            line-height: 1;
            color: $c-ash;

            &--topic {
                font-family: $font-family-medium;
            }

            span {
                position: relative;
                margin-right: 3px;
                padding-right: 8px;
                font-size: 0.875rem;

                &:not(:last-child)::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 2px;
                    right: 0;
                    bottom: 3px;
                    width: 2px;
                    background-color: $c-ash;
                }
            }
        }

        .byline {
            margin-bottom: element-spacing-units(2);
            font-size: 0.75rem;
            text-transform: uppercase;

            &-by {
                margin-bottom: 0;
                color: $c-ash;
            }

            &-names {
                @include list-unstyled();
                margin: 0;
            }

            &-name {
                font-family: $font-family-medium;
            }

            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: -2px;
                width: 48px;
                height: 3px;
                background-color: $c-carbon;
            }
        }
    }

    &-image {
        margin-top: element-spacing-units(1);
        margin-bottom: element-spacing-units(4);

        @include tablet {
            margin-bottom: element-spacing-units(2);
        }

        .article-content & {
            @include tablet {
                margin-right: -60px;
                margin-left: -60px;
            }

            @include phone {
                margin-right: -24px;
                margin-left: -24px;
            }
        }
    }

    &-body {
        max-width: 700px;
    }

    &-text {
        .abstract,
        .body {
            max-width: 920px;
            line-height: 1.45;

            a {
                padding-right: 1px;
                padding-left: 1px;
                color: currentColor;
                background-color: $c-white;
                border-bottom: 2px solid $c-corporate;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: currentColor;
                    background-color: $c-rose;
                }

                &.btn--secondary {
                    padding-right: 28px;
                    padding-left: 28px;
                    color: $c-black;
                    background-color: transparent;
                    border: .0625rem solid currentColor;
                }

                &.btn--cta {
                    padding-right: unset;
                    padding-left: unset;
                    background-color: unset;
                    border: none;
                }
            }
        }

        .abstract p:last-child {
            margin-bottom: 0;
        }

        .body {
            > ul {
                padding-left: 0;
                list-style-type: none;

                > li {
                    position: relative;
                    padding-left: 24px;

                    &::before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 0.2rem;
                        width: 0.75rem;
                        height: 0.75rem;
                        margin-right: 10px;
                        margin-left: -22px;
                        background-color: $c-chatelle;

                        @include phone {
                            margin-left: -24px;
                        }
                    }
                }
            }

            li + li {
                margin-top: 0.33rem;
            }

            p > img {
                margin-bottom: 1rem;

                @include tablet-up {
                    &[style*="float: right"],
                    &[style*="float: left"] {
                        max-width: 60%;
                    }

                    &[style*="float: right"] {
                        margin-left: 30px;
                    }

                    &[style*="float: left"] {
                        margin-right: 30px;
                    }
                }

                @include phone {
                    &[style*="float: right"],
                    &[style*="float: left"] {
                        float: none !important;
                        display: block;
                    }
                }
            }
        }

        .container:has(.EPiServerForms) {
            padding: 15px;
            background-color: #f8f9fa !important;

            .EPiServerForms {
                padding: 0;
            }
        }
    }
}

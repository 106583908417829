@import "../_variables";

.magazine-layover-block {
    .article-page &,
    .start-page & {
        margin-right: -15px;
        margin-left: -15px;

        @include tablet {
            margin-right: -60px;
            margin-left: -60px;
        }

        @include phone {
            margin-right: -24px;
            margin-left: -24px;
        }
    }

    body:not(.loaded) {
        visibility: hidden;
    }

    .article-text .body & {
        margin-bottom: 2rem;
    }

    &.division {
        &--silicon-products,
        &--carbon-solutions {
            .case-story {
                &__opener,
                &__close.btn {
                    color: $c-black;
                }
            }

            .case-story__close.btn svg path {
                fill: $c-black;
            }
        }

        &--dark,
        &--corporate,
        &--silicones {
            .case-story {
                &__opener,
                &__close.btn {
                    color: $c-white;
                }
            }

            .case-story__close.btn svg path {
                fill: $c-white;
            }
        }

        &--dark {
            .case-story {
                &__opener,
                &__close.btn {
                    background-color: $c-charcoal;
                }
            }
        }

        &--corporate {
            .case-story {
                &__opener,
                &__close.btn {
                    background-color: $c-corporate;
                }
            }
        }

        &--carbon-solutions {
            .case-story {
                &__opener,
                &__close.btn {
                    background-color: $c-carbon;
                }
            }
        }

        &--silicones {
            .case-story {
                &__opener,
                &__close.btn {
                    background-color: $c-silicones;
                }
            }
        }

        &--silicon-products {
            .case-story {
                &__opener,
                &__close.btn {
                    background-color: $c-silicon-products;
                }
            }
        }
    }

    .start-page,
    .article-page {
        > div:not(.container) & {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    &__teaser {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 88vw;
        max-height: 590px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        .col-lg-6 & {
            align-items: flex-end;
            justify-content: flex-start;
        }

        @include desktop {
            .fullwidth &,
            .start-page > div:not(.container) & {
                align-items: center;
                justify-content: flex-end;
                padding-right: 5vw;
            }

            .col-lg-6 & {
                justify-content: flex-end;
            }

            .col-lg-6 + .col-lg-6 & {
                justify-content: flex-start;
            }
        }

        @include phone {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            background-image: none !important;
        }

        .case-story__teaser-image {
            display: none;
            
            @include phone {
                display: block;
                width: 100%;
                height: 217px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }

        .case-story__opener {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 217px;
            padding: 75px 80px;
            font-family: $font-family-book;
            font-size: 2.25rem;
            line-height: 1.3;
            color: $c-white;
            background-color: $c-charcoal;

            .label {
                margin-bottom: .5rem;
                font-size: 1.125rem;
                text-transform: uppercase;
            }

            @include desktop {
                .fullwidth &,
                .start-page > div:not(.container) & {
                    width: 40vw;
                    min-width: 650px;
                }

                .col-lg-6 & {
                    padding: 40px 28px;
                    font-size: 1.75rem;
                }
            }

            @include large-desktop {
                .col-lg-6 & {
                    font-size: 2.25rem;
                }
            }

            @include tablet-up {
                .col-lg-6 & {
                    width: 80%;
                    min-width: 0;
                }
            }

            @include tablet {
                font-size: 1.75rem;

                .label {
                    font-size: 1rem;
                }
            }

            @include tablet-only {
                .fullwidth &,
                .start-page > div:not(.container) & {
                    width: 80%;
                }
            }

            @include phone {
                width: 100%;
                padding: 40px;
                font-size: 1.25rem;

                .label {
                    font-size: .875rem;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }

            &:hover,
            &:focus {
                text-decoration: none;

                .title {
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    text-decoration-thickness: from-font;
                }
            }
        }
    }

    .case-story {
        &__modal {
            position: fixed;
            z-index: 2000;
            top: 0;
            bottom: 0;
            width: 100vw;
            background-color: $c-black-75;
            opacity: 0;
            transition: opacity .25s ease-out;

            &.shown {
                left: 0;
                opacity: 1;

                .case-story__inner {
                    left: 240px;
    
                    @include tablet {
                        left: calc(42vw - 182px);
                    }
        
                    @include phone-narrow {
                        left: 20px;
                    }
                }
            }
        }

        &__inner {
            position: absolute;
            top: 0;
            right: 0;
            left: 100%;
            bottom: 0;
            width: calc(100% - 240px);
            padding-top: 3.8125rem;
            background-color: $c-white;
            transition: left .4s ease-out;

            @include tablet {
                width: calc(100% - (42vw - 182px));
            }

            @include phone-narrow {
                width: calc(100% - 20px);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            padding: 100px 20px 20px;
            color: $c-black;

            h1 {
                max-width: 920px;
            }
            .article-text {
                .abstract,
                .body {
                    max-width: 920px;
                }
            }

            h1 {
                font-size: 36px;
            }
        }

        &__image {
            position: relative;
            width: calc(100% + 40px);
            min-height: 32vw;
            margin: 2rem -20px 4rem;
            background-position: center;
            background-size: cover;
        }

        &__close.btn {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            z-index: 2001;
            padding: 10px 20px;
            background-color: $c-charcoal;
            border: none;
        }
    }
}
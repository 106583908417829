.latest-news-block {
    max-width: 1152px;
    margin: 4rem auto;
    padding-right: 15px;
    padding-left: 15px;

    @include phone {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__heading {
        padding-bottom: 1rem;
        text-transform: uppercase;
        text-align: center;
        color: $c-charcoal;
        border-bottom: 1px solid #d9d9d9;

        @include phone {
            padding-bottom: 1rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 51px;
        margin-top: 3rem;

        @include phone {
            margin-top: 1rem;
        }

        .list-item {
            flex-basis: calc(33.333% - 34px);

            a {
                &:hover {
                    text-decoration: none;

                    .list-item__title {
                        text-decoration: underline;
                        text-underline-offset: 3px;
                        text-decoration-thickness: from-font;
                    }

                    .list-item__image img {
                        transform: scale(1.1);
                    }
                }
            }

            &__image {
                overflow: hidden;

                img {
                    aspect-ratio: 3 / 2;
                    object-fit: cover;
                    transition: transform .75s ease-in-out;
                }
            }

            &__text {
                padding: 10px;
            }

            &__date {
                font-size: .875rem;
                color: $c-ash;
            }

            &__title {
                margin-top: 1rem;
                font-family: $font-family-book;
                font-size: 1.25rem;
            }
        }

        @include tablet {
            gap: 21px;

            .list-item {
                flex-basis: calc(33.333% - 14px);

                &__title {
                    font-size: 1.125rem;
                }
            }
        }

        @include phone {
            display: block;

            .list-item {
                + .list-item {
                    margin-top: 2rem;
                }
            }
        }
    }

    &__gotoall {
        margin-top: 2rem;
        text-align: right;

        .btn {
            display: inline-flex;
            gap: 8px;
            align-items: center;
            min-width: 0;
            font-size: 1.125rem;
            color: $c-corporate;
        }
    }
}
﻿html {
    font-size: 16px;
    box-sizing: border-box;
    scroll-behavior: smooth;
    @include display-flex;
    @include flex-direction(column);
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    @include display-flex;
    @include flex-flow(column, nowrap);
    justify-content: space-between;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    font-family: $font-family-book;
    font-size: $font-size-base;
    color: $c-black;
    line-height: $line-height-lg;

    &.darkmode {
        z-index: -5;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-filter: grey;
        filter: gray
    }

    html[data-useragent*='rv:11.0'] & {
        /* hack for IE11, removes 1px horizontal scrollbar on body tag*/
        overflow-x: hidden;
    }

    @include tablet {
        padding-top: 112px;

        &.startpage,
        &.division--startpage {
            padding-top: 76px;

            &.magazine {
                padding-top: 74px;
            }
        }
    }

    @include desktop {
        padding-top: 225px;

        &.startpage,
        &.division--startpage {
            padding-top: 186px;
        }

        &.magazine {
            padding-top: 222px;

            &.startpage {
                padding-top: 184px;
            }
        }
    }

    @include xlarge-desktop {//large-desktop (magazine adaptation)
        padding-top: 169px;

        &.startpage,
        &.division--startpage {
            padding-top: 130px;
        }

        &.magazine {
            padding-top: 168px;

            &.startpage {
                padding-top: 130px;
            }
        }
    }

    @include phone {
        padding-top: 164px;
        font-size: $font-size-mobile;

        &.startpage,
        &.division--startpage {
            padding-top: 128px;
        }

        &.magazine {
            padding-top: 158px;

            &.startpage {
                padding-top: 126px;
            }
        }
    }

    & > header:first-of-type {
        @include flex-item(0, 0, auto);
    }

    & > main {
        @include flex-item(1, 1, auto);
        padding-right: 15px;
        padding-left: 15px;

        @include tablet {
            padding-right: 60px;
            padding-left: 60px;
        }

        @include phone {
            padding-right: 24px;
            padding-left: 24px;
        }
    }

    & > footer:last-of-type {
        @include flex-item(0, 0, auto);
    }
}

a.skip-main {
    position: absolute;
    top: 7px;
    left: 7px;
    z-index: 1000;
    padding: 3px 8px;
    color: $c-black;
    background-color: $c-white;

    &:not(:focus) {
        @include sr-only;
    }
}

#top-of-page {
    position: absolute;
    top: -131px;
}

.to-top-of-page {
    display: flex;
    justify-content: flex-end;
    max-width: 1740px;
    margin-right: auto;
    margin-left: auto;

    &__inner {
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;

        @include tablet {
            padding-right: 0;
        }
    }

    &__link {
        display: flex;
        position: fixed;
        bottom: 30px;
        z-index: 100;
        margin-top: 0;
        padding: 12px 14px;
        font-size: 14px;
        color: $c-black;
        background-color: transparent;
        border: 0.0625rem solid currentColor;
        border-radius: 6px;
        background-color: $c-white;
        opacity: 1;
        transition: opacity .3s ease-in-out;

        @include tablet {
            right: 10px;
            bottom: 10px;
        }

        svg {
            transform: rotate(-90deg);
        }

        &:hover {
            text-decoration: none;

            svg path {
                fill: currentColor;
            }
        }

        &:not(.shown):not(:focus) {
            opacity: 0;
        }
    }
}

i.elk-icon {
    font-style: normal;
}

img {
    max-width: 100%;

    .article-content &,
    .page-section & {
        height: auto;
    }
}

figure {
    margin-bottom: 2rem;

    figcaption {
        margin-top: 0.75rem;
        font-size: 0.875rem;
        color: $c-ash;
        padding-bottom: 5px;
        border-bottom: 1px solid $c-beige;
    }

    &.float-right {
        @include desktop {
            float: right;
            max-width: 483px;
            margin-left: 30px;

            .body & {
                max-width: 60%;
            }
        }

        @include tablet {
            float: none !important;
        }
    }

    &.float-left {
        @include desktop {
            float: left;
            max-width: 483px;
            margin-right: 30px;

            .body & {
                max-width: 60%;
            }
        }

        @include tablet {
            float: none !important;
        }
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: 0;
    font-family: $font-family-medium;
}

h1, .h1 {
    font-size: 3.375rem;
    line-height: 1.3;
    margin: 0 0 2rem;

    @include tablet {
        font-size: 2.5rem;
    }

    @include phone {
        font-size: 1.75rem;
    }

    &.align-center {
        text-align: center;

        .article-text & {
            max-width: 920px;
        }
    }
}

h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    p + & {
        margin-top: 0.5rem;
    }

    div + & {
        margin-top: 2rem;
    }
}

h2, .h2 {
    font-size: 1.5rem;
    line-height: 1.4;

    @include tablet {
        font-size: 1.375rem;
    }

    @include phone {
        font-size: 1.25rem;
    }
}
h2.bigger {
    font-size: 2.375rem;

    @include tablet {
        font-size: 1.5rem;
    }

    @include phone {
        font-size: 1.375rem;
    }
}

h3, .h3 {
    font-size: 1.25rem;
    line-height: 1.4;

    @include tablet {
        font-size: 1.125rem;
    }
}

h4, .h4 {
    font-size: 1.125rem;
    line-height: 1.4;

    @include tablet {
        font-size: 1.0625rem;
    }
}

h5, .h5,
h6, .h6 {
    font-size: 1rem;
    line-height: 1.25;
}

p {
    margin: 0 0 $paragraph-spacing;
}

b,
strong {
    font-family: $font-family-medium;
    font-weight: 300;
}

.abstract {
    padding-bottom: 1rem;
    font-size: 1.5rem;

    @include tablet {
        font-size: 1.25rem;
    }
}

ul {
    &.no-bullets {
        margin-left: 0;
        padding: 0;
        list-style-type: none;
    }
}

ul,
ol {
    margin-bottom: $paragraph-spacing;
}

.flex {
    &-row {
        @include flex;
        flex-direction: row;
        -webkit-flex-direction: row;
    }

    &-column {
        @include flex;
        flex-direction: column;
        -webkit-flex-direction: column;
    }
}

.call-to-action {
    a {
        font-weight: bold;
        color: inherit;

        &:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
}

ul.no-style {
    list-style: none;
    margin: 0;
    padding: 0;
}

.collapse {
    &.in,
    &.show {
        display: block;
    }
}

.invisible {
    visibility: hidden;
}

.page-section {
    padding-top: element-spacing-units(1);

    @include tablet {
        padding-top: element-spacing-units(3);
    }

    &:not(:last-child) {
        padding-bottom: element-spacing-units(4.625);

        @include phone {
            padding-bottom: element-spacing-units(1);
        }

        @include tablet {
            padding-bottom: element-spacing-units(2.75);
        }

        .startpage & {
            padding-bottom: 0 !important;
        }
    }

    .magazine & {
        padding-bottom: 0;
    }

    .page-content > div:first-child + &,
    .page-content > &:first-of-type,
    .hero-block + & {
        padding-top: 2.5rem;

        @include phone {
            padding-top: 1.5rem;
        }
    }

    + .page-section {
        padding-top: 0;
    }

    main > .hero-block + & {
        padding-top: 0;
    }

    .block-content-page-heading + & {
        padding-top: 0;
    }

    &.filled {
        background: $c-gray;
    }
}

.article {
    &-list {
        margin-bottom: element-spacing-units();
    }
}

.page-sidebar {
    position: relative;
    padding-top: 2rem;

    > *:first-child:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        left: 15px;
        border-top: 6px solid $c-corporate;
    }

    .magazine & {
        padding-top: 0;

        > *:first-child:before {
            display: none;
        }

        &-inner {
            padding-left: 24px;
            border-left: 6px solid $c-silicones;

            h2 {
                margin-bottom: 0;
                padding-bottom: 0.75rem;
                font-size: 0.75rem;
                text-transform: uppercase;
                color: $c-ash;
                border-bottom: 1px solid $c-concrete;
            }

            ul {
                @include list-unstyled();
                margin: 0;

                li {
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    font-family: $font-family-medium;
                    font-size: 0.875rem;
                    border-bottom: 1px solid $c-concrete;
                }
            }

            &.chapters {
                border-left: 1px solid $c-concrete;

                h2 {
                    margin-bottom: 1rem;
                    border-bottom: 3px solid $c-silicon-products;
                }

                ul li {
                    padding-top: 0;
                    padding-bottom: 0;
                    border-bottom: none;

                    + li {
                        margin-top: 0.75rem;
                    }
                }
            }
        }
    }
}

@include tablet {
    .article-text + .page-sidebar {
        margin-top: 2rem;
    }
}

a.document-file {
    position: relative;
    display: block;
    @include flex-item(0, 0, auto);
    padding: 0.5rem 2rem 0.5rem 0;

    &::after {
        content: '';
        position: absolute;
        top: 0.7rem;
        right: 0;
        display: block;
        width: 14px;
        height: 14px;
        background: url('/Static/img/icons/elk-arrow-down.svg') center center / contain no-repeat;
    }
}

.block {
    &-list {
        @include display-flex;
        @include flex-flow(row, wrap);
        justify-content: flex-start;
        align-items: stretch;
        margin-left: -15px;
        margin-right: -15px;
        @include flex-item(1, 1, auto);

        &-item {
            @include display-flex;
            @include flex-direction(column);
            padding: element-spacing-units(1);
            width: 100%;

            .attachment-block {
                border-bottom: 1px solid black;
            }

            .col-50 > & {
                width: 50%;
                @include flex-item(0, 1, auto);
                padding: 1rem;

                @include tablet {
                    width: 100%;
                }
            }

            .col-33 > & {
                width: 33.33333%;
                @include flex-item(0, 1, auto);
                padding: 1rem;

                @include desktop-down {
                    width: 50%;
                }

                @include tablet {
                    width: 100%;
                }
            }

            .col-25 > & {
                width: 25%;
                @include flex-item(0, 1, auto);
                padding: 1rem;

                @include tablet {
                    width: 50%;
                }

                @include phone-narrow {
                    width: 100%;
                }
            }

            .col-50-33 > & {
                width: 33.33333%;
                @include flex-item(0, 1, auto);
                padding: 1rem;

                @include tablet {
                    width: 50%;
                }

                @include phone {
                    width: 100%;
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                }
            }

            & > .attachment {
                &-link {
                    display: block;
                    @include flex-item(0, 0, auto);
                }
            }

            & > .link {
                &-download {
                    display: block;
                    @include flex-item(0, 0, auto);
                }
            }

            & > .section-heading {
                padding-top: element-spacing-units(2);

                & > h3 {
                    margin: 0;
                    font-weight: 600;
                }
            }
        }
    }
}

.link {
    &-download {
        position: relative;
        display: block;
    }
}

.plus {
    &-padding {
        padding: $padding-size-plus;

        &-top {
            padding-top: $padding-size-plus;
        }

        &-bottom {
            padding-bottom: $padding-size-plus;
        }

        &-vertical {
            padding-top: $padding-size-plus;
            padding-bottom: $padding-size-plus;
        }

        &-left {
            padding-left: $padding-size-plus;
        }

        &-right {
            padding-right: $padding-size-plus;
        }

        &-horizontal {
            padding-left: $padding-size-plus;
            padding-right: $padding-size-plus;
        }
    }

    &-margin {
        margin: $padding-size-plus;

        &-top {
            margin-top: $padding-size-plus;
        }

        &-bottom {
            margin-bottom: $padding-size-plus;
        }

        &-vertical {
            margin-top: $padding-size-plus;
            margin-bottom: $padding-size-plus;
        }

        &-left {
            margin-left: $padding-size-plus;
        }

        &-right {
            margin-right: $padding-size-plus;
        }

        &-horizontal {
            margin-left: $padding-size-plus;
            margin-right: $padding-size-plus;
        }
    }
}

.minus {
    &-padding {
        padding: 0 !important;

        &-top {
            padding-top: 0 !important;
        }

        &-bottom {
            padding-bottom: 0 !important;
        }

        &-vertical {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        &-left {
            padding-left: 0 !important;
        }

        &-right {
            padding-right: 0 !important;
        }

        &-horizontal {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    &-margin {
        margin: 0 !important;

        &-top {
            margin-top: 0 !important;
        }

        &-bottom {
            margin-bottom: 0 !important;
        }

        &-vertical {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        &-left {
            margin-left: 0 !important;
        }

        &-right {
            margin-right: 0 !important;
        }

        &-horizontal {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}

.elk {
    &-flag {
        background-position: center center;
        background-size: cover;
        display: inline-block;
        width: 16px;
        height: 11px;
        margin-top: 6px;
        margin-left: 1rem;
        vertical-align: text-top;
    }

    &-arrow {
        &:after {
            position: relative;
            display: inline-block;
            font-family: 'elk-icons' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            margin-left: 1em;
            content: '\e911';
        }
    }
}

.label {
    &-date {
        display: block;
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
        padding: 0;

        + h1 {
            .article-content & {
                margin-top: 0.75rem;
            }
        }
    }

    &-preheader {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.large-text {
    font-size: 1.25rem;
}

.small-text {
    font-size: 0.875rem;
}

.normal {
    &-forced {
        font-size: 1rem !important;
        line-height: 1.4 !important;
    }
}

table {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;

    caption {
        font-weight: bold;
        color: inherit;
    }

    th,
    td {
        padding-right: 10px;
        padding-left: 10px;
        vertical-align: top;
        border: 0;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    img {
        min-width: 60px;
        height: auto;
    }
}

.elk-table {
    width: 100%;
    margin-bottom: element-spacing-units(4);

    tr {
        border-top: 1px solid black;
    }

    tr:first-child {
        border-top: none;
    }

    td,
    th {
        height: 3rem;

        &.align-right {
            text-align: right;
        }

        &.more-padding-right {
            padding-right: 1rem;
        }
    }

    &-hidden {
        display: none;
    }
}

.hidden {
    display: none !important;
}
.faq {
    &-toc {
        &::before {
            display: none !important;
        }

        &__title {
            margin-bottom: 1rem;
            padding-bottom: 0.75rem;
            font-size: 0.75rem;
            text-transform: uppercase;
            color: #686b6e;
            border-bottom: 3px solid #ec1211;
        }

        &__list {
            margin-left: 0;
            padding-left: 0;
            font-family: $font-family-medium;
            font-size: 0.875rem;
            list-style-type: none;

            li + li {
                margin-top: 0.75rem;
            }
        }
    }

    &-item {
        &:first-of-type {
            margin-top: 2rem;
        }
    }
}
﻿@import "../variables";
@import "../mixins";

.news-list {
	margin-bottom: element-spacing-units();
	@include display-flex;
	@include flex-flow(row, wrap);
	justify-content: flex-start;
	align-items: stretch;

	&.col-50,
	&-col-33,
	&-col-25 {
		margin-left: element-spacing-units(-1);
		margin-right: element-spacing-units(-1);
	}

	&-item {
		@include display-flex;
		@include flex-direction(column);
		/*@include debugBorder;*/
		.col-50 > & {
			width: 50%;
			@include flex-item(0,1,auto);
			padding: element-spacing-units();

			@include tablet {
				width: 100%;
			}
		}

		.col-33 > & {
			width: 33.33333%;
			@include flex-item(0,1,auto);
			padding: element-spacing-units();

			@include tablet {
				width: 50%;
			}

			@include phone {
				width: 100%;
			}
		}

		.col-25 > & {
			width: 25%;
			@include flex-item(0,1,auto);
			padding: element-spacing-units();

			@include tablet {
				width: 50%;
			}

			@include phone {
				width: 100%;
			}
		}

		& > .content-block {
			background: $c-gray;
			@include display-flex;
			@include flex-flow(column, nowrap);
			justify-content: space-between;
			@include flex-item(1,0,auto);

			& > .image {
				@include flex-item(0,0,auto);
			}

			& > .text {
				@include flex-item(1,1,auto);
				@include display-flex;
				@include flex-flow(column, nowrap);
				justify-content: space-between;

				& > h5,
				& > h4 {
					@include flex-item(0,0,auto);
				}

				@include tablet-up {
					& > h4 {
						@include line-clamp(3);//2 / 4
						min-height: 66px;//45px / 86px
					}
				}

				& > .cta {
					@include flex-item(0,0,auto);
					@include display-flex;
					@include flex-direction(row);
					justify-content: flex-start;

					&:hover {
						text-decoration: none;

						.link-text {
							text-decoration: underline;
						}
					}
				}

				& > .block-text-wrapper {
					@include flex-item(1,0,auto);
					margin: 0;

					@include tablet-up {
						& > .block-text {
							@include line-clamp(6);
						}
					}
				}
			}
		}
	}
}
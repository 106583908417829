﻿@import "_variables.scss";
@import "_mixins.scss";

body:not(.loaded) {
    .flyover--container,
    .search-container,
    .hamburger-container,
    .flyover--menu-close-container {
        display: none;
    }
}

button,
input,
select,
textarea {
    max-width: 100%;
}

input,
select,
textarea {
    &.form-control {
        border: 1px solid $c-chatelle;
        border-radius: 2px;

        @include placeholder {
            color: $c-mineshaft;
        }

        &:focus {
            border: 1px solid $c-black;
            box-shadow: 0 0 0;
        }

        &.error {
            border-color: $c-corporate;
        }
    }
}

select {
    border: 1px solid $c-mineshaft;
}

.elk {
    &-checkbox {
        position: relative;

        & > input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;

            & + label {
                position: relative;
                display: inline-block;
                font-weight: 300;
                padding-left: 32px;
                margin: 0 0 1rem 0;
                font-size: 0.875rem;
                cursor: pointer;

                & > .indicator {
                    @include display-flex;
                    @include flex-flow(column, nowrap);
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    margin-top: -2px;
                    background: $c-white;
                    border: 1px solid $c-chatelle;
                    border-radius: 2px;

                    &::after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 12px;
                        height: 12px;
                        margin-top: -6px;
                        margin-left: -6px;
                        background: url('/Static/img/icons/elk-checkmark-white.svg') center center / contain no-repeat;
                    }
                }
            }

            &:focus {
                & + label > .indicator {
                    border-color: $c-black;
                }
            }

            &:checked {
                & + label {
                    & > .indicator {
                        background: $c-black;
                        color: $c-white;

                        &:before {
                            @extend .elk-icon;
                            @extend .elk-checkmark-fat;
                        }
                    }
                }
            }

            &[disabled] {
                & + label {
                    color: $c-gray-medium;
                    cursor: default;

                    & > .indicator {
                        background: $c-gray;

                        &:before {
                            @extend .elk-icon;
                            @extend .elk-close;
                            color: $c-white-50;
                        }
                    }
                }
            }
        }
    }
}

.btn {
    &.btn-search {
        min-width: 0;
        font-size: 1rem;
        transition: none;

        .icon-search {
            display: inline-block;
            position: relative;
            top: 0.25rem;
            width: 21px;
            height: 21px;
            margin-right: 5px;
            margin-left: 5px;

            .st0 {
                fill: none;
                stroke: $c-black;
                stroke-width: 2;
                stroke-linecap: round;
                stroke-linejoin: round;
            }

            .st1 {
                fill: none;
                stroke: $c-black;
                stroke-width: 2;
            }

            .magazine & {
                .st0,
                .st1 {
                    stroke: $c-white;
                }
            }
        }
    }

    &.btn-close {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 1rem;//1.8rem
        right: 60px;
        min-width: 0;
        transition: none;

        svg {
            width: 12px;
            height: 12px;
            margin-left: 8px;
        }

        .magazine & {
            color: $c-white;

            svg path {
                fill: $c-white;
            }
        }

        &:hover,
        &:focus {
            color: $c-corporate;

            svg path {
                fill: $c-corporate;
            }

            .magazine & {
                color: currentColor;
                border-bottom: 1px solid $c-white;

                svg path {
                    fill: currentColor;
                }
            }
        }
    }
}

.search-container {
    position: relative;

    .container {
        padding-right: 0;
        padding-left: 0;
    }

    @include tablet {
        .btn-close {
            display: none;

        }
    }
}

.form {
    &-search {
        width: 100%;

        @include tablet {
            display: flex;
        }

        .form-group {
            &.search-field {
                position: relative;
                margin-bottom: 0;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                width: 100%;
                max-width: 670px;

                .search-label {
                    .search-container & {
                        position: absolute;
                        left: -999em;
                    }
                }

                & > .btn-search {
                    position: absolute;
                    top: 0.75rem;
                    right: 20px;
                    z-index: 10;
                    min-width: 0;
                    min-height: 0;
                    padding: 0;
                    background: none;
                    border: 0;

                    @include phone {
                        top: 0.65rem;
                    }
                }

                & > input[type="search"].form-control {
                    padding: 13px 20px;
                    line-height: 1;
                    display: block;
                    width: 100%;
                    font-size: 1.25rem;
                    background-color: $c-white;
                    border: 1px solid $c-ash;
                    border-radius: 6px;
                }

                &.is-active {
                    .search-label {
                        position: absolute;
                    }
                }
            }
        }
    }

    &-group {
        margin-bottom: 1.5rem;

        .validation {
            &-error {
                color: $c-red;

                &[aria-hidden="false"] {
                    display: block;
                }
            }
        }
    }

    &-control {
        padding: 0.75rem 1rem;
        font-size: inherit;
        height: auto;
        min-height: 2.75rem;

        &-appendix {
            .form-group & {
                display: block;
                padding: element-spacing-units(1) 0;
            }
        }

        &-group {
            &.align {
                &-right {
                    text-align: right;
                }
            }
        }
    }
}

textarea.form-control {
    padding: 1rem;
}

.btn {
    border-radius: 0;
    padding: 1rem;
    // min-width: 7rem;
    font-family: inherit;
    font-size: 1rem;
    text-align: center;

    &-elk {
        &-primary {
            background: $c-mineshaft;
            color: $c-white;
            transition: all 0.3s cubic-bezier(0.2, 0.1, 0.2, 1);

            &:hover,
            &:focus {
                color: $c-white;
                background: $c-gray-dark;
            }
        }

        &-secondary {
            background: $c-blue;
            color: $c-white;
            transition: all 0.3s cubic-bezier(0.2, 0.1, 0.2, 1);

            &:hover,
            &:focus {
                color: $c-white;
                background: $c-gray-dark;
            }
        }

        &-outline {
            display: inline-block;
            width: auto;
            min-width: auto;
            @include flex-item(0, 0, auto);
            align-self: flex-start;
            font-family: $font-family-medium;
            font-weight: 500;
            background: $c-white;
            border: 2px solid $c-mineshaft;
            border-radius: 20px;
            padding: 0.4rem 1.5rem;
        }
    }

    &.btn--primary,
    &.btn--cta {
        &,
        .article-text .abstract &,
        .article-text .body & {
            padding: 13px 30px;
            font-size: 1.125rem;
            color: $c-white;
            background-color: $c-corporate;
            border: none;
            border-radius: 6px;
            transition: all 0.3s cubic-bezier(0.2, 0.1, 0.2, 1);

            &:not(:disabled) {
                &:hover,
                &:focus,
                &:active {
                    color: $c-white;
                    background: $c-corporate-darker;
                }

                &:active {
                    border-color: $c-corporate-darker;
                }
            }

            &.text-color-white {
                color: $c-white;

                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        color: $c-white;
                    }
                }
            }

            &.text-color-black {
                color: $c-black;

                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        color: $c-black;
                    }
                }
            }

            &.division--corporate {
                background-color: $c-corporate;

                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $c-corporate-darker;
                    }
                }
            }

            &.division--silicones {
                background-color: $c-silicones;

                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $c-silicones-darker;
                    }
                }
            }

            &.division--silicon-products {
                background-color: $c-silicon-products;

                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $c-silicon-products-darker;
                    }
                }
            }

            &.division--carbon-solutions {
                background-color: $c-carbon;

                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $c-carbon-darker;
                    }
                }
            }

            &.bg-color-white {
                padding: 12px 29px;
                background-color: #fff;
                border: 1px solid #000;

                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $c-gray;
                    }
                }
            }
        }
    }

    &--secondary {
        padding: 12px 28px;
        font-size: 1.125rem;
        color: $c-black;
        background-color: transparent;
        border: .0625rem solid currentColor;
        border-radius: 6px;
        transition: all 0.3s cubic-bezier(0.2, 0.1, 0.2, 1);
    }

    &--tag {
        min-width: 0;
        margin-right: 3px;
        margin-bottom: 7px;
        padding: 7px 12px;
        font-size: 1rem;
        color: $c-black;
        background-color: transparent;
        border: .0625rem solid currentColor;
        border-radius: 6px;
        transition: all 0.3s cubic-bezier(0.2, 0.1, 0.2, 1);
    }

    &-reset {
        padding: 0;
        font-family: inherit;
        font-size: inherit;
        background-color: transparent;
        border: none;
    }

    &-link {
        text-decoration: underline;
    }

    &-selector {
        position: relative;
        padding-right: 17px;

        svg {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 3px;
            width: 12px;
            height: 12px;
            transform: rotate(180deg);

            .magazine & {
                fill: $c-white;
            }
        }

        &.collapsed svg {
            transform: rotate(0deg);
        }
    }

    &:hover {
        color: $c-corporate;
        border-color: $c-corporate;
    }
}

html[data-useragent*="MacOs"] {
    select {
        -webkit-appearance: none;
    }
}

// EpiServer Forms
.EPiServerForms {
    max-width: 670px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px;
    background-color: #f8f9fa !important;

    .Form__Element__Caption {
        text-align: left;
    }

    .Form__Element,
    .Form__Element > div[lang] {
        @include display-flex;
        @include flex-direction(column);
        justify-content: flex-start;

        &__Caption {
            margin-bottom: 0.375rem;
        }

        & > label,
        & > input,
        & > select {
            @include flex-item(0, 1, auto);
            font-weight: 300;
        }

        & > select {
            background: $c-white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23343a40' d='M16 18.811l-11.6-11.046c-0.124-0.162-0.317-0.265-0.534-0.265s-0.41 0.103-0.532 0.263l-0.001 0.002-2.066 1.984c-0.163 0.121-0.267 0.313-0.267 0.529s0.104 0.408 0.265 0.528l0.002 0.001 14.2 13.5c0.142 0.123 0.329 0.198 0.533 0.198s0.391-0.075 0.534-0.199l-0.001 0.001 14.2-13.495c0.163-0.121 0.267-0.313 0.267-0.529s-0.104-0.408-0.265-0.528l-0.002-0.001-2.066-1.989c-0.124-0.162-0.317-0.265-0.534-0.265s-0.41 0.103-0.532 0.263l-0.001 0.002z'/%3E%3C/svg%3E") right .75rem center no-repeat;
            background-size: 20px 14px;
        }

        & > input,
        & > select,
        & > textarea {
            @include placeholder {
                font-size: 0.8rem;
                color: $c-black;
            }

            padding: .375rem 2.25rem .375rem .75rem;
            height: auto;
            min-height: 2.125rem;
            font-size: 1rem;
            line-height: 1.5;
            border: 1px solid $gray-400;
            border-radius: 0.25rem !important;
            outline: none;

            .filled & {
                background: $c-white;
                border-color: $c-white;
            }
        }

        &.ValidationFail {
            & > input,
            & > select,
            & > textarea {
                border-color: $c-corporate;
            }
        }

        .submit-container > button {
            background: $c-corporate !important;
            color: $c-white !important;
            font-family: system-ui, -apple-system !important;
            border-radius: 4px !important;
            text-align: center !important;
            align-items: center !important;
            padding: 9px 30px !important;
            font-size: 1rem !important;
            border: 0 !important;
            overflow: hidden;

            &:hover{
                background: $c-corporate !important;
                color:$c-white !important;
            }

            &::after{
                filter:invert(1);
            }

            &::before{
                filter:invert(1);
            }
        }
    }

    .Form__NavigationBar {
        display: flex;
        align-items: center;

        @include tablet {
            display: grid;
            grid-template-columns: auto 140px;
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;

            .btnPrev { grid-area: 1 / 1 / 2 / 2; }
            .btnNext { grid-area: 1 / 2 / 2 / 3; }
            .Form__NavigationBar__ProgressBar {
                grid-area: 2 / 1 / 3 / 3;
                margin-right: 0;
                margin-left: 0;
            }
        }

        &__ProgressBar {
            flex-basis: calc(50% - 20px);

            @include tablet-up {
                margin-right: 10px;
                margin-left: 10px;
            }

            @include tablet {
                width: 100% !important;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        &__Action {
            flex-basis: 25%;

            &.btnPrev,
            &.btnNext {
                min-width: 1px;
            }

            &.btnPrev::before {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 2px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                background-image: url('/Static/img/icons/elk-chevron-down.svg');
                transform: rotate(90deg);
            }

            &.btnNext::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-left: 5px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                background-image: url('/Static/img/icons/elk-chevron-down.svg');
                transform: rotate(-90deg);
            }
        }
    }

    button {
        @extend .btn;
        @extend .btn-elk-primary;
    }
}

p.consent-information {
    margin-top: 2rem;
    font-size: 0.825rem;
    font-style: italic;
    line-height: 1.4;
    text-align: left;
}
﻿@import "../_variables.scss";
@import "../_mixins.scss";

.hero-block {
    position: relative;
    min-height: 32vw;
    margin-right: -15px;
    margin-left: -15px;
    background-position: center;
    background-size: cover;

    .startpage .page-section & {
        margin-bottom: element-spacing-units(4.625);

        @include phone {
            margin-bottom: element-spacing-units(1);
        }

        @include tablet {
            margin-bottom: element-spacing-units(2.75);
        }
    }

    .single-column-content & {
        min-height: auto;
    }

    &-inner {
        padding-right: 30px;
        padding-left: 30px;
    }

    @include tablet {
        margin-right: -60px;
        margin-left: -60px;

        .single-column-content & {
            margin-right: -15px;
            margin-left: -15px;
        }

        &-inner {
            padding-top: 4vw;
            padding-bottom: 4vw;
        }
    }

    .blog-post & {
        margin-right: 0;
        margin-left: 0;
    }

    @include phone {
        min-height: 15.375rem;
        margin-right: -24px;
        margin-left: -24px;

        &-inner {
            padding: 2rem 9px;
        }

        .single-column-content & {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    &-inner::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 60%, rgba(0,0,0,0.1) 80%, rgba(0,0,0,0) 100%);
    }

    .page-content > *:last-child & {
        margin-bottom: 0;
    }

    .text-card {
        margin-top: 3rem;
        margin-bottom: 3rem;
        color: $c-white;

        a {
            color: $c-white;
        }

        @include desktop {
            .single-column-content & {
                margin: 5rem 40px;
            }
        }

        @include tablet {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        @include phone {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        .body {
            font-size: 3rem;
            line-height: 1.1;

            .small-text {
                font-size: 1.25rem;
            }

            .large-text {
                font-size: 1.75rem;
            }

            @include desktop {
                &.font-size-xxl {
                    font-size: 6rem;

                    .small-text {
                        position: relative;
                        top: -1rem;
                        font-size: 4rem;
                    }
                }
                &.font-size-xl {
                    font-size: 5rem;

                    .small-text {
                        position: relative;
                        top: -1rem;
                        font-size: 3.5rem;
                    }
                }
                &.font-size-l {
                    font-size: 4rem;

                    .small-text {
                        font-size: 2.5rem;
                    }
                }
                &.font-size-n {
                    font-size: 3rem;
                    line-height: 1.2;

                    .small-text {
                        font-size: 2rem;
                    }
                }
                &.font-size-s {
                    font-size: 2rem;
                    line-height: 1.3;

                    .small-text {
                        font-size: 1.75rem;
                    }
                }
            }

            @include tablet {
                &.font-size-xxl {
                    font-size: 4rem;
                }
                &.font-size-xl {
                    font-size: 3.5rem;
                }
                &.font-size-l {
                    font-size: 3rem;
                }
                &.font-size-n {
                    font-size: 2.5rem;
                }
                &.font-size-s {
                    font-size: 2rem;
                }
            }

            @include phone {
                &.font-size-xxl,
                &.font-size-xl {
                    font-size: 3rem;
                }
                &.font-size-l {
                    font-size: 2rem;
                }
                &.font-size-n {
                    font-size: 1.75rem;
                }
                &.font-size-s {
                    font-size: 1.5rem;
                }
            }

            p {
                margin-bottom: 0;
                font-size: inherit;
                line-height: inherit;

                + p {
                    margin-top: 0.5rem;
                }
            }
        }

        .footer {
            margin-top: 1rem;
            font-size: 1.5rem;
            line-height: 1.2;
            color: $c-white;

            @include phone {
                margin-top: 0;
                font-size: 1rem;
            }

            a {
                display: inline-block;
                color: $c-white;

                &::after {
                    content: '';
                    display: inline-block;
                    position: relative;
                    top: 6px;
                    width: 28px;
                    height: 28px;
                    background: transparent url('/Static/img/icons/elk-arrow-right-white.svg') center center / 20px no-repeat;
                }
            }
        }
    }

    &.text-color-black {
        .text-card {
            color: $c-black;

            a {
                color: $c-black;
            }
        }
    }

    .video-viewport {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;

        .video-container {
            min-width: 100%;
            min-height: 120%;
        }

        video {
            min-width: 100%;
            min-height: 100%;
        }

        @include phone {
            display: none;
        }
    }
}
.article-content .speak-up {
    &-textbox {
        padding: 1rem;
        border: 1px solid #000;

        *:last-child {
            margin-bottom: 0;
        }
    }

    &-link {
        display: inline-block;
        margin-top: .5rem;
        margin-bottom: .5rem;
        padding: 2rem 3rem;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        text-underline-position: initial;
        color: #fff;
        background-color: #0a5194;

        &:hover,
        &:focus {
            text-decoration: underline;
            color: #fff;
        }
    }
}
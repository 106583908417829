.division-intro {
    @include flex;
    @include flex-flow(row, nowrap);

    &__image {
        flex-basis: 40%;

        @include tablet {
            flex-basis: 35%;
        }
    }

    &__text {
        flex-basis: 60%;
        padding: 80px;
        font-size: 1.5rem;
        background-color: $c-concrete;

        @include tablet {
            flex-basis: 65%;
            padding: 20px;
            font-size: 1.25rem;
        }
    }

    @include phone {
        flex-direction: column;
        margin-bottom: 40px;

        &__text {
            font-size: 1rem;
        }
    }
}
﻿.document-list-page {
    .section-heading {
        margin-bottom: element-spacing-units(2);

        @include phone {
            margin-bottom: element-spacing-units(1);
        }

        &-expand {
            h3 {
                display: inline-block;
                position: relative;
                margin-bottom: 0;
                padding: 3px 10px 3px 30px;

                &:after {
                    content: '+';
                    position: absolute;
                    top: -0.1rem;
                    left: 5px;
                    font-size: 2rem;
                    font-weight: 100;
                    line-height: 1;
                }
            }

            &.expanded h3:after {
                content: '–';
                top: -0.2rem;
            }

            &:focus,
            &:hover {
                color: $c-blue;
            }
        }
    }

    .document-table {
        .document-row {
            cursor: pointer;

            &:hover {
                background: $c-gray-xlight;
            }
        }
    }
}

﻿@import "_variables";
@import "_mixins";

body.available-in-chinese {
    @include phone {
        padding-top: 118px;
    }

    .available-in-chinese-msg {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-in;

        a {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            padding: 10px;
            font-size: .875rem;
            color: $c-corporate;

            img {
                width: 50px;
                height: auto;
            }
        }

        @include tablet {
            position: absolute;
            top: 20px;
            left: 240px;
        }

        @include phone {
            top: 68px;
            right: 0;
            left: 0;
            background-color: $c-white;

            a img {
                width: 40px;
            }
        }

        &.show {
            max-height: 4rem;

            @include desktop {
                border-bottom: 1px solid $c-chatelle;
            }
        }
    }
}

.site-header {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
    font-size: 1rem;
    transition: top 0.3s ease-in-out;
    background-color: $c-white;

    &.site-header-off {
        top: -14rem;
    }

    .hide-on-mobile {
        @include tablet {
            display: none !important;
        }
    }

    .hide-on-desktop {
        @include desktop {
            display: none !important;
        }
    }

    & > .container {
        margin-bottom: 1.8rem;
    }

    &--theme {
        @include tablet-only {
            min-height: 70px;
        }

        @include phone {
            min-height: 126px;

            &:has(.search-container:empty) {
                min-height: 68px;
            }
        }

        &::after {
            content: '';
            display: block;
            width: 0.1%;
            height: 2px;
            background-color: $c-corporate;
            transition: width 0.3s ease-in-out;

            .magazine & {
                background: linear-gradient(90deg, $c-corporate 0%, $c-corporate 64%, $c-silicones 64%, $c-silicones 76%, $c-carbon 76%, $c-carbon 88%, $c-silicon-products 88%, $c-silicon-products 100%);
            }

            .loaded & {
                width: 100%;
            }

            @include tablet {
                display: none;
            }
        }
    }

    .container--wide {
        max-width: 100%;
    }

    .site-logo-nav {
        display: flex;
        align-items: center;
        gap: 36px;
        padding: 24px 34px 24px 20px;

        @include large-desktop {//desktop (magazine adaptation)
            min-height: 100px;

            .search-container {
                flex-grow: 1;
            }
        }

        @include xlarge-desktop-down {//desktop-down (magazine adaptation)
            display: grid;
            grid-template-columns: 150px auto;
            grid-template-rows: auto auto;
            gap: 16px 32px;
            grid-auto-flow: row;
            grid-template-areas: "logo nav" "search search";
            min-height: 156px;

            .site-logo-container {
                grid-area: logo;
            }
            .search-container {
                grid-area: search;
            }
            .site-navigation,
            .search-and-menu-mobile {
                grid-area: nav;
            }

            &:has(.search-container:empty) {
                row-gap: 0;
            }
        }

        @include tablet-only {
            row-gap: 0;
            height: 74px;
        }

        @include tablet {
            min-height: 74px;
            padding-top: 0;
            padding-bottom: 0;

            &.mobile-menu-open {
                .site-logo {
                    display: none;
                }
            }
        }

        @include phone {
            height: 96px;
            padding-top: 58px;
            padding-bottom: 15px;

            &:has(.search-container:empty) {
                height: 68px;
                padding-top: 28px;
            }
        }

        .site-logo-container {
            flex-basis: 135px;
            flex-grow: 0;
            flex-shrink: 0;

            @include tablet-only {
                margin-top: -2px;
                padding-left: 12px;
            }

            @include phone {
                margin-top: -24px;
            }

            .logos {
                display: flex;
                align-items: center;
                width: 340px;
                gap: 20px;
                padding-right: 20px;
                border-right: 1px solid $c-gray-light;

                @include tablet {
                    border-right: none;
                }

                @include desktop {
                    width: 361px;
                }

                .award-logo {
                    width: auto;
                    height: 27px;

                    @include tablet {
                        height: 38px;
                    }

                    @include desktop {
                        height: 46px;
                    }
                }
            }
        }

        .site-navigation {
            flex-basis: auto;
            flex-grow: 0;
            flex-wrap: wrap;

            @include desktop {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 48px;
                flex-direction: row;
            }

            @include xlarge-desktop-down { //desktop-down (magazine adaptation)
                justify-content: flex-end;
            }

            @include tablet {
                display: none;
            }
        }

        .search-container {
            @include xlarge-desktop-down { //desktop-down (magazine adaptation)
                justify-self: center;
                width: 520px;
                max-width: 100%;
            }

            @include tablet-only {
                position: absolute;
                width: 400px;
                margin-top: -2px;
                margin-left: 50px;
            }

            @include phone {
                position: relative;
                top: 8px;
                width: 100%;
            }

            atomic-search-interface {
                margin-bottom: 0;
            }

            .elkem-global-search {
                .search-box-container {
                    padding: 0;
                }

                atomic-search-box {
                    width: 100% !important;
                    max-width: 520px;

                    @include tablet {
                        &::part(input) {
                            padding-top: 9px;
                            padding-bottom: 9px;
                        }
                    }

                    @include phone {
                        max-width: 100%;
                    }
                }

                atomic-layout-section[section='search'] .search-box-container {
                    place-items: flex-start;
                }
            }
        }

        .search-and-menu-desktop {
            @include desktop {
                flex-basis: 100px;
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 5px;
                padding-left: 5px;
                text-align: right;
            }

            @include tablet {
                display: none;
            }
        }

        .site-logo {
            display: inline-block;
            width: 124px;
            height: 24px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;

            @include phone {
                width: 90px;
                height: 18px;
                margin-top: 3px;
            }

            span {
                @include sr-only();
            }
        }

        @include tablet {
            &:not(.mobile-menu-open)::after {
                content: '';
                display: block;
                position: absolute;
                right: 100%;
                left: 0;
                height: 2px;
                background-color: $c-corporate;
                transition: right 0.3s ease-in-out;

                .magazine & {
                    background: linear-gradient(90deg, $c-corporate 0%, $c-corporate 64%, $c-silicones 64%, $c-silicones 76%, $c-carbon 76%, $c-carbon 88%, $c-silicon-products 88%, $c-silicon-products 100%);
                }

                .loaded & {
                    right: 0;
                }
            }

            .site-logo-container {
                width: auto;

                a {
                    display: block;
                }
            }
        }

        @include tablet-only {
            &:not(.mobile-menu-open)::after {
                top: 74px;
            }
        }

        @include phone {
            &:not(.mobile-menu-open) {
                &::after {
                    top: 126px;
                }

                &:has(.search-container:empty)::after {
                    top: 68px;
                }
            }
        }
    }

    .search-and-menu-mobile,
    .search-and-menu-desktop {
        .btn-search {
            position: relative;
            width: 40px;
            height: 40px;
            transition: all 0.3s ease-in-out;

            &::before {
                content: "";
                position: absolute;
                top: 22.5%;
                right: 23.5%;
                width: 0;
                height: 2px;
                margin-top: -2px;
                background-color: $c-black;
                transform: rotate(-45deg);
                transform-origin: right top;

                .magazine & {
                    background-color: $c-white;
                }
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 22.5%;
                right: 23.5%;
                width: 29%;
                height: 2px;
                margin-top: -2px;
                background-color: $c-black;
                transform: rotate(45deg);
                transform-origin: right bottom;

                .magazine & {
                    background-color: $c-white;
                }
            }

            div {
                position: absolute;
                display: block;
                top: 20%;
                left: 20%;
                width: 45%;
                height: 45%;
                border-radius: 50%;
                border-width: 2px;
                border-style: solid;
                border-color: $c-black;

                .magazine & {
                    border-color: $c-white;
                }
            }

            &.active::before,
            &.active::after {
                width: 77.5%;
            }

            &.active div {
                width: 0;
                height: 0;
                border-color: transparent;
            }

            &::before,
            &::after,
            div {
                transition: all 0.3s ease-in-out;
            }
        }

        .btn-menu {
            min-width: 0;
            border: none;
            background: transparent;

            &,
            svg {
                width: 48px;
                height: 48px;
            }

            @include phone {
                &,
                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .btn-menu {
        width: 51px;
        min-width: 0;
        height: 40px;
        border: none;
        background: transparent;

        .bar {
            display: block;
            width: 37px;
            height: 4px;
            margin: 7px auto;
            background-color: $c-charcoal;
            transition: all .3s ease;
            border-radius: 2px;

            .magazine & {
                background-color: $c-white;
            }

            &-second {
                margin: 0 auto;
            }
        }

        &:not(.collapsed) {
            .bar {
                width: 38px;

                &-first {
                    transform: translateY(12px) rotateZ(45deg);
                }

                &-second {
                    width: 0;
                }

                &-third {
                    transform: translateY(-10px) rotateZ(-45deg);
                }
            }
        }
    }

    nav {
        font-size: 0.875rem;
        padding: 0.25rem 0;

        &.nav-global {
            min-height: 29px;
            color: white;
            background-color: $c-charcoal;

            body.magazine & {
                min-height: 28px;
                border-bottom: 1px solid $c-elkem-grey;
            }
        }
    }

    .brand-logo {
        height: 60px;
    }

    .breadcrumbs {
        padding: 0.5rem 15px;
        color: $c-charcoal;
        background-color: $c-concrete;

        .magazine & {
            padding-top: 0;
            padding-bottom: 0;
        }

        .noscroll & {
            visibility: hidden;
        }

        .container {
            max-width: 950px;
        }

        li {
            display: inline-block;
            line-height: 1.2;
            font-size: 0.875rem;

            .magazine & {
                padding-top: 0.6875rem;
                padding-bottom: 0.6875rem;
            }

            &:not(:first-child)::before {
                content: "";
                display: inline-block;
                position: relative;
                top: 0.0625rem;
                width: 10px;
                height: 10px;
                margin: 0 8px 0 4px;
                background: url('/Static/img/icons/elk-arrow-right.svg') center center / contain no-repeat;
            }

            a {
                font-size: inherit;
                display: inline-block;
                color: $c-charcoal;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                &:active {
                    text-decoration: none;
                }
            }

            @include phone {
                display: none;

                &:nth-last-child(2) {
                    display: inline-block;

                    &::before {
                        width: 16px;
                        height: 12px;
                        margin-left: 0;
                        background-image: url('/Static/img/icons/elk-arrow-left.svg');
                    }
                }
            }
        }

        .magazine & {
            color: #000;
        }
    }

    .navigation-links {
        padding: 0.88rem 0rem;
        color: $c-mineshaft;

        li {
            $fontSize: 1rem;

            line-height: 1.2;
            display: inline-block;

            &:before {
                content: " ";
                margin: 0 0.5rem;
            }

            &:first-child:before {
                margin: 0;
                content: none;
            }

            a {
                font-size: $fontSize;
                display: inline-block;
                color: $c-mineshaft;
                border-bottom: 1px solid currentColor;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                    opacity: 0.8;
                }

                &:active {
                    text-decoration: none;
                }
            }

            span {
                color: $c-mineshaft;
                font-weight: bold;
            }
        }
    }

    .nav {
        &-divider {
            display: block;
            height: 5px;
            margin-bottom: 5px;
            padding: 0 5px;

            &:before {
                display: block;
                content: "";
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                height: 5px;
                width: 100%;
            }
        }

        &-global {
            @include tablet {
                display: none;
            }

            &-list {
                @include display-flex;
                @include flex-flow(row, nowrap);
                justify-content: flex-end;
                align-items: center;
                margin-right: 20px;

                & > li {
                    padding-right: 1.75rem;

                    a {
                        color: $c-black;
                    }

                    &.nav-i18l {
                        &:before {
                            display: inline-block;
                            content: "|";
                            padding-right: 1.25em;
                            font-size: 1.2em;
                            line-height: 1em;
                        }

                        .elk-icon {
                            display: inline-block;
                            padding-right: 0.25em;
                        }
                    }

                    &:last-of-type {
                        padding-right: 0;
                    }

                    &.language-list {
                        display: flex;
                        flex-direction: row;

                        > span:first-child {
                            margin-right: 5px;
                        }

                        .btn-link {
                            color: white;

                            svg path {
                                fill: white;
                            }
                        }

                        .language-selector {
                            position: absolute;
                            top: 29px;
                            right: 30px;
                            z-index: 1003;
                            margin: 0;
                            padding: 0 20px;
                            list-style: none;
                            color: $c-charcoal;
                            background-color: $c-wild-sand;

                            .magazine & {
                                background-color: $c-charcoal;
                            }

                            li {
                                &:first-child {
                                    margin-top: 20px;
                                }
                                &:last-child {
                                    margin-bottom: 20px;
                                }

                                &.current span {
                                    font-family: $font-family-medium;
                                }

                                + li {
                                    margin-top: 10px;
                                }
                            }

                            span,
                            a {
                                position: relative;
                                display: inline-block;
                                padding-left: 25px;
                                line-height: 1;

                                img {
                                    position: absolute;
                                    top: -2px;
                                    left: 0;
                                    display: inline-block;
                                    width: 20px;
                                    height: 15px;
                                }
                            }

                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        &-local {
            @include tablet {
                display: none;
            }

            &-list {
                @include display-flex;
                @include flex-flow(row, nowrap);
                justify-content: flex-end;
                align-items: center;

                @include desktop-down {
                    @include flex-flow(row, wrap);
                }

                & > li {
                    @include flex-item(0, 0, auto);
                    align-self: flex-start;
                    font-size: 1rem;
                    line-height: 1.77778rem;

                    a,
                    span {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 100%;
                        border: none;
                        padding: 0;
                        color: $c-black;
                        background-color: transparent;
                        font-size: 1rem;
                        text-align: left;
                        border-bottom: .1rem solid transparent;

                        .magazine & {
                            color: $c-white;
                        }
                    }

                    a:hover {
                        text-decoration: none;
                        color: $c-mineshaft;
                        border-bottom-color: $c-corporate;

                        .magazine & {
                            color: $c-wild-sand;
                        }
                    }
                }
            }
        }

        &-mobile {
            display: none;

            @include tablet {
                display: block;

                body:not(.loaded) & {
                    display: none;
                }
            }

            .search-and-menu-mobile {
                position: absolute;
                top: 11px;
                right: 32px;
                z-index: 1501;
                display: flex;
                align-items: flex-end;
            }

            &-dropdown {
                position: fixed;
                top: 70px;
                left: 0;
                right: 0;
                height: calc(100% - 92px);
                box-sizing: border-box;
                z-index: 1500;
                padding-top: 0;
                padding-bottom: 0;
                background-color: $c-white;
                transition: height 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

                @include tablet {
                    overflow-x: hidden;
                }

                @include desktop {
                    position: absolute;
                    top: 91px;
                    height: 0;
                }

                @include phone {
                    top: 124px;
                    height: calc(100% - 124px);
                }

                .magazine & {
                    background-color: $c-charcoal;
                }

                // &.in,
                &.show {
                    max-height: 100vh;
                    height: calc(100% - 96px);
                }

                .flyover--container {
                    background-color: $c-white;

                    @include desktop {
                        border-bottom: 2px solid $c-corporate;
                    }

                    .magazine & {
                        background-color: $c-charcoal;
                    }
                }

                .mobile--bottom {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    padding: 0;
                    margin: 0 24px;
                    font-size: 1rem;

                    > li {
                        flex-basis: 100%;
                        padding: 0.25rem 24px;
                        text-align: left;

                        &.language-list {
                            margin-top: 2rem;
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                            background-color: $c-concrete;
                        }
                    }

                    .language-list {
                        span,
                        a {
                            padding: 11px 7px;
                        }

                        a {
                            display: inline-block;
                            line-height: 1;
                            margin-right: 32px;
                            margin-bottom: 0.5rem;
                            padding: 4px 0;
                            color: $c-ash;

                            @include phone {
                                margin-right: 15px;
                            }

                            &.current {
                                color: $c-black;
                                border-bottom: 1px solid $c-corporate;
                            }

                            img {
                                display: inline-block;
                                position: relative;
                                top: -2px;
                                width: 20px;
                                height: 15px;

                                @include phone {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            &-list {
                @include display-flex;
                @include flex-flow(column, nowrap);
                justify-content: flex-start;
                padding: 1rem 24px;
                list-style-type: none;

                & > li {
                    position: relative;
                    padding-top: 0.8rem;
                    padding-bottom: 0.8rem;
                    font-size: 1.125rem;

                    &:not(.flyover--item-search) {
                        border-top: 2px solid $c-concrete;
                    }

                    &:last-child {
                        border-bottom: 2px solid $c-concrete;
                    }

                    &.flyover--item-search {
                        margin-top: 2rem;
                        margin-bottom: 3rem;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}

.site {
    &-search {
        &-desktop {
            @include tablet {
                display: none;
            }
        }

        &-mobile {
            display: none;

            @include tablet {
                display: block;
            }
        }
    }

    &-logo-nav {
        @include tablet {
            padding: element-spacing-units(1.5) 0 0 0;
        }
    }

    &-footer {
        padding: 2.75rem 0rem;
        background-color: $c-white;
        border-top: 6px solid $c-corporate;

        .magazine & {
            margin-top: 0;
            color: $c-white;
        }

        .magazine & {
            background-color: $c-charcoal;
        }

        > .container {
            @include tablet {
                padding-right: 60px;
                padding-left: 60px;
            }

            @include phone {
                padding-right: 24px;
                padding-left: 24px;
            }
        }

        h2 {
            margin-bottom: 1rem;
            font-size: 0.875rem;
            font-weight: 400;
            color: $c-ash;

            .magazine & {
                color: $c-chatelle;
            }
        }

        a,
        a:hover,
        a:visited,
        a:focus {
            color: $c-black;

            .magazine & {
                color: $c-white;
            }
        }

        ul {
            @include list-blank;
            @include display-flex;
            @include flex-flow(column, nowrap);
            justify-content: flex-start;

            li {
                @include flex-item(0, 0, auto);
                padding: 1px 0;
                font-size: 0.875rem;
            }
        }

        .block-list-item {
            width: 15.2%;

            @include tablet {
                & {
                    width: 100%;
                }
            }
        }
    }
}

.copyright {
    padding: 1rem 0;
    color: $c-black;
    background-color: $c-concrete;

    > .container {
        @include tablet {
            padding-right: 60px;
            padding-left: 60px;
        }

        @include phone {
            padding-right: 24px;
            padding-left: 24px;
        }
    }

    .row {
        @include display-flex;
        justify-content: space-between;

        .col-50 {
            @include flex-item(1,0,auto);
            align-self: baseline;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .site-logo {
        max-width: 84px;
    }

    .text-right {
        font-size: 0.75rem;
    }
}

body:has(.site-header--theme .search-container:empty) {
    @include tablet {
        padding-top: 112px;
    }

    @include desktop {
        padding-top: 165px;

        &.magazine {
            padding-top: 162px;
        }
    }

    @include phone {
        padding-top: 100px;

        &.magazine {
            padding-top: 98px;
        }
    }

    .site-logo-nav {
        @include xlarge-desktop-down {
            min-height: 88px;
        }

        @include tablet {
            min-height: 74px;
        }
    }
}
﻿@import "../_variables.scss";
@import "../_mixins.scss";
@import "../_fonts.scss";

.attachment {
	&-block {
		width: 100%;
		padding-bottom: element-spacing-units(1);
		margin-bottom: element-spacing-units(1);

		&-content {
			& > * {
				display: block;
				@include flex-item(0,0,auto);
				padding: element-spacing-units(0.5) 0;
				margin: 0;
			}

			& > h3 {
				padding-top:0;
				font-size: 1rem;
			}

			& > .attachment-thumb {
				margin: 0.5rem 0;
			}
		}
	}

	&-link {
		position: relative;
		display: block;
		width: 100%;
		padding-top: 5px;
		padding-right: 20px;
		padding-bottom: 5px;

		&::after {
			content: '';
			position: absolute;
			top: 3px;
			right: 0;
			display: block;
			width: 20px;
			height: 20px;
			background: url('/Static/img/icons/elk-arrow-down.svg') center center / contain no-repeat;
		}
	}
}
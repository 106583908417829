.location-page {
    > .fullwidth {
        @include desktop {
            margin-left: -15px;
            margin-right: -15px;
        }

        @include tablet {
            margin-left: -60px;
            margin-right: -60px;
        }

        @include phone {
            margin-left: -24px;
            margin-right: -24px;
        }

        .container {
            padding-right: 15px;
            padding-left: 15px;
        }

        .table-two-cols {
            padding-right: 15px;
            padding-left: 15px;

            @include phone {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}
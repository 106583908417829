﻿@import "../variables";
@import "../mixins";

.article {
    margin-bottom: element-spacing-units(1);//4.625
	color: $c-black;

	@include tablet {
		margin-bottom: element-spacing-units(2);
	}

	&-preamble,
	&-abstract {
		max-width: 700px;
		font-family: $font-family-medium;
	}

	a,
	&-preamble a {
		padding-right: 1px;
		padding-left: 1px;
		color: currentColor;
		background-color: $c-white;
		border-bottom: 2px solid $c-corporate;

		&:hover,
		&:focus {
			text-decoration: none;
			color: currentColor;
		}
	}

	a {
		&:hover,
		&:focus {
			background-color: $c-rose;
		}
	}

	.contentarea-fullwidth & {
		max-width: $xl-min-width;
		margin: 0 auto;

		& > h2,
		& > .article-preamble,
		& > .article-content {
			padding-right: 25%;

			@include tablet {
				padding-right: 0;
			}
		}
	}

	.container & {
		padding-right: 25%;

		@include tablet {
			padding-right: 0;
		}
	}

	.news-list &,
	.block-list & {
		padding-right: 0;
	}
}
blockquote {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 2rem;

    @include tablet {
        font-size: 1.5rem;
    }

    @include phone {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 1.25rem;
    }

    cite {
        position: relative;
        display: block;
        margin-top: 3rem;
        font-size: 1rem;
        font-style: normal;
        color: $c-ash;

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: -2rem;
            left: 0;
            width: 90px;
            height: 6px;
            background-color: $c-carbon;
        }
    }
}
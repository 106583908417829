﻿.contact-us-block {
    @include tablet {
        margin-right: -60px;
        margin-left: -60px;
    }

    @include phone {
        margin-right: -24px;
        margin-left: -24px;
    }

    &__emphasize {
        background-color: $c-elkem-grey;
    }

    .contentarea-extended.fullwidth {
        margin-top: 0;
    }

    &__list {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @include tablet {
            padding-right: 60px;
            padding-left: 60px;
        }

        @include phone {
            padding-right: 24px;
            padding-left: 24px;
        }
    }

    &__header {
        padding-top: 2.5rem;
        padding-bottom: 1rem;

        @include phone {
            padding-top: 1.5rem;
        }
    }

    h1 {
        margin-bottom: 1.5rem;
        font-family: $font-family-book;
        font-size: 2.5rem;
        color: $c-corporate;

        @include tablet {
            font-size: 1.75rem;
        }

        @include phone {
            font-size: 1.5rem;
        }
    }

    .tags {
        margin-top: 2.5rem;
    }

    @include tablet {
        .container.stripe {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
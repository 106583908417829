@import "../_variables";

.cta-page-block {
    body:not(.loaded) {
        visibility: hidden;
    }

    .article-text .body & {
        margin-bottom: 2rem;
    }

    &.theme {
        &--silicon-products,
        &--carbon {
            div:not(.col-lg-6) > & {
                &,
                .btn--secondary {
                    color: $c-black;
                }

                .btn--secondary:hover {
                    border-color: $c-black;
                    outline: 1px solid $c-black;
                }
            }
        }

        &--dark,
        &--corporate,
        &--silicones {
            div:not(.col-lg-6) > & {
                &,
                .btn--secondary {
                    color: $c-white;
                }

                .btn--secondary:hover {
                    border-color: $c-white;
                    outline: 1px solid $c-white;
                }
            }
        }

        &--dark {
            div:not(.col-lg-6) > & {
                background-color: $c-charcoal;
            }
        }

        &--corporate {
            div:not(.col-lg-6) > & {
                background-color: $c-corporate;
            }
        }

        &--carbon {
            div:not(.col-lg-6) > & {
                background-color: $c-carbon;
            }
        }

        &--silicones {
            div:not(.col-lg-6) > & {
                background-color: $c-silicones;
            }
        }

        &--silicon-products {
            div:not(.col-lg-6) > & {
                background-color: $c-silicon-products;
            }
        }
    }

    .startpage & {
        .container {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .container {
        display: flex;
        gap: 20px;

        @include phone {
            flex-direction: column;
        }

        @include tablet-up {
            flex-direction: row;
        }

        .col-lg-6 & {
            @include phone {
                flex-direction: column;
            }

            @include desktop {
                .cta-page-block__text {
                    flex-basis: 100%;
                }
            }
        }
    }

    @include desktop {
        .col-lg-6 & {
            .cta-page-block__image {
                display: none;
            }
        }
    }

    &__text,
    &__image {
        flex-basis: calc(50% - 10px);
    }

    &__text {
        padding: 20px;

        h2 {
            font-family: $font-family-book;
            font-size: 1.5rem;
        }
    }

    &__image img {
        height: auto;
        object-fit: cover;

        @include tablet-up {
            height: 100%;
        }
    }

    @include tablet-up {
        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem 30px;
        }

        .stripe--even & {
            .container {
                flex-direction: row-reverse;
            }
        }
    }

    @include desktop {
        &__text {
            padding: 4rem 66px;

            .col-lg-8 & {
                padding: 2rem 40px 2rem 30px;
            }

            h2 {
                margin-bottom: 1rem;
            }

            p {
                margin-bottom: 2rem;
                font-size: 1.125rem;
            }
        }
    }

    &.case-story {
        .cta-page-block__image {
            position: relative;

            .case-story__opener {
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 80%;
                min-width: 300px;
                min-height: 217px;
                padding: 28px;
                font-family: $font-family-medium;
                font-size: 2.25rem;
                line-height: 1.3;
                color: $c-black;
                background-color: $c-silicon-products;

                div {
                    max-width: 300px;
                }

                .label {
                    margin-bottom: .5rem;
                    font-size: 1.125rem;
                    text-transform: uppercase;
                }

                @include tablet {
                    font-size: 1.75rem;

                    .label {
                        font-size: 1rem;
                    }
                }

                @include phone {
                    font-size: 1.5rem;

                    .label {
                        font-size: .875rem;
                    }
                }

                @include tablet-up {
                    .stripe--odd & {
                        right: 0;
                        left: auto;
                    }
                }

                &:hover,
                &:focus {
                    text-decoration: none;

                    .title {
                        text-decoration: underline;
                        text-underline-offset: 3px;
                        text-decoration-thickness: from-font;
                    }
                }
            }
        }
    }

    .case-story__modal {
        display: none;

        &.shown {
            display: block;
            position: fixed;
            z-index: 2000;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $c-black-75;
        }

        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            left: 240px;
            bottom: 0;
            padding-top: 3.8125rem;
            background-color: $c-white;

            @include tablet {
                left: calc(42vw - 182px);
            }

            @include phone-narrow {
                left: 20px;
            }
        }

        &-content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            color: $c-black;
        }

        .btn.case-story__close {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            padding: 10px;
            background-color: $c-silicon-products;
            border: none;

            svg {
                margin-left: 30px;
            }
        }
    }
}
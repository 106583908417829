﻿@import "_variables";
@import "_fonts";
@import "_mixins";

$mobile-border-color: $c-concrete;

[v-cloak] {
    display: none;
}

html.noscroll {
    body {
        position: fixed;
        right: 0;
        left: 0;
    }

    main {
        padding-right: 32px;

        @-moz-document url-prefix() {
            padding-right: 16px;
        }
    }

    .backdrop {
        visibility: visible;
        bottom: 0;
    }
}

.backdrop {
    position: fixed;
    top: 120px;
    left: 0;
    right: 0;
    visibility: hidden;
    overflow-y: auto;
    background-color: $c-white-75;
    z-index: 990;

    @include desktop-only {
        top: 200px;
    }

    @include tablet {
        top: 100vh;
    }

    .magazine & {
        background-color: $c-black-75;
    }
}

.flyover {
    > .container {
        position: relative;
    }

    &--container {
        position: relative;
    }

    &.site-header {
        .col-md-7 {
            position: static;
        }

        .nav-local {
            display: flex;
            align-items: center;
            gap: 36px;

            .nav-local-list {
                flex-flow: row wrap;
                justify-content: flex-end;
                gap: 24px;

                .body-magazine & {
                    gap: 20px;
                }
            }
        }
    }

    .nav-mobile-dropdown.collapse {
        // &.in,
        &.show {
            display: flex;
            flex-direction: column;
        }
    }

    &--menu {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        background-color: $c-white;
        padding: 0;
        max-height: 0;
        overflow: hidden;

        .magazine & {
            background-color: $c-charcoal;
        }

        &:not(.open) {
            display: none;
        }

        &.open {
            max-height: 85vh;
            overflow-y: auto;
            border-bottom: .1rem solid $c-corporate;
        }

        @media (max-width: 991px) {
            display: none;
        }

        &-content,
        &-content-lower {
            padding: 2rem;

            .container {
                display: flex;
                flex-direction: row;
                width: 100%;
                max-width: 1440px;
            }
        }

        &-content-lower {
            background-color: $c-concrete;
        }

        &-close-container {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;

            .container {
                text-align: right;
            }
        }
    }

    &--item {
        position: relative;
        z-index: 1002;
        border: none;
        transition: 0.2s;

        > button,
        > span,
        > a {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            border: none;
            padding: 0;
            color: $c-black;
            background-color: transparent;
            font-size: 1rem;
            text-align: left;
            border-bottom: .1rem solid transparent;

            .magazine & {
                color: $c-white;
            }
        }

        > button,
        > a {
            &:hover {
                text-decoration: none;
                color: $c-mineshaft;
                border-bottom-color: $c-corporate;

                .magazine & {
                    color: $c-wild-sand;
                }
            }
        }

        > a,
        > span,
        > button {
            border-bottom: .1rem solid transparent;

            @include tablet {
                border-bottom: none;
            }
        }

        > button {
            position: relative;

            @include desktop {
                padding-right: 20px;

                svg {
                    width: 14px;
                    height: 14px;
                    margin-top: 0.5rem;
                    transform: rotate(0deg);
                }
            }

            @include tablet {
                > svg {
                    position: absolute;
                    top: 1rem;
                    right: 0;
                    height: 16px;
                    transform: rotate(-90deg);

                    .magazine & {
                        path {
                            fill: $c-white;
                        }
                    }
                }
            }
        }

        @include desktop {
            &.active svg {
                transform: rotate(180deg);
            }
        }

        &.active > button {
            color: $c-corporate;

            &::after {
                transform: rotate(180deg);
            }

            svg path {
                fill: $c-corporate;
            }
        }

        &-search.mobile {
            margin-top: 2rem;
            margin-bottom: 3rem;

            + .flyover--item.mobile:not(.list-heading) {
                border-top: 2px solid $mobile-border-color;
            }
        }

        &.mobile {
            font-size: 1rem;
            padding: 0;

            > button::after {
                right: 0;
            }

            > button,
            > span,
            > a {
                position: relative;
                padding-top: 0.8rem;
                padding-right: 24px;
                padding-bottom: 0.8rem;
                font-size: 1.125rem;

                &::after {
                    top: calc(50% - 8px);
                }

                .has-icon {
                    padding-left: 35px;

                    .icon {
                        position: absolute;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        width: 25px;
                        height: 25px;
                        margin-left: -35px;
                    }
                }
            }

            .bold {
                font-family: $font-family-medium;
            }

            &.top-level,
            &.list-heading {
                padding: 0;
            }

            > button {
                font-family: $font-family-medium;
                font-size: 1.125rem;
                display: flex;
                gap: 5px;
            }

            &.top-level {
                border-bottom: 1px solid $c-chatelle;

                > a,
                > span {
                    font-family: $font-family-medium;
                    font-size: 1.125rem;
                }

                button,
                span,
                a {
                    img {
                        margin-top: -0.1rem;
                    }
                }

                &.has-icon {
                    margin-bottom: .75rem;
                    border-bottom-color: $c-chatelle;

                    > button {
                        font-size: 1.25rem;
                    }
                }

                &.is-title {
                    margin-bottom: .5rem;
                    padding-bottom: .5rem;
                    border-bottom-color: $c-chatelle;

                    img {
                        margin-top: -0.2rem;
                    }
                }
            }

            &.is-bigger {
                margin-bottom: 1rem;
                border-bottom: 1px solid $c-abbey;

                &:first-child {
                    margin-bottom: .5rem;
                }

                > button {
                    padding-top: 1.3rem;
                    padding-bottom: .5rem;
                    font-size: 1.25rem;

                    .has-icon {
                        padding-left: 50px;

                        .icon {
                            margin-left: -50px;
                        }
                    }

                    @include tablet {
                        > svg {
                            top: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    .has-underlines li:not(.is-title) {
        border-bottom: 1px solid $c-chatelle;
    }

    &--division-link {
        > a,
        > span {
            font-family: $font-family-medium;
        }

        > &.current > a {
            border-bottom-color: $c-black;
        }

        &.mobile {
            padding-bottom: 1rem !important;
            border-bottom: none;

            > a {
                text-align: center;
            }
        }
    }

    &--extra {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-top: 0.25rem;
        font-size: 0.875rem;

        &:hover,
        &:focus {
            text-decoration: none;
            color: currentColor;

            .flyover--extra-title {
                text-decoration: underline;
            }
        }

        > img {
            max-width: 26px;
            max-height: 26px;
            margin-bottom: auto;
        }

        > div {
            margin: auto;
            width: calc(100% - 20px);
            flex: 1 1 auto;
            padding-left: 1rem;

            p {
                margin-bottom: 0;
            }
        }

        &-title {
            font-weight: bold;
            padding-bottom: 0.25rem;
        }
    }

    &--list {
        position: relative;
        z-index: 1001;
        width: 100%;
        max-height: 100%;
        margin-top: 20px;
        padding: 10px 0;
        list-style: none;

        &-desktop {
            justify-content: center;
        }

        &.flyover--list-first:first-child {
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            margin-left: 0;

            .flyover--list-item {
                margin-top: -5px;
                margin-bottom: 10px;

                > a,
                > span {
                    font-family: $font-family-medium;
                    border-bottom: 1px solid $c-gray-light;
                }

                &.has-submenu {
                    > button {
                        padding-right: 44px;

                        &:hover,
                        &:focus {
                            padding-right: 32px;
                        }
                    }

                    &.active > button {
                        padding-right: 32px;
                        padding-left: 12px;
                    }
                }
            }
        }

        &-extras {
            top: 3rem;
            padding-right: 40px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 1px;
                background-color: $mobile-border-color;
            }

            + .flyover--list.flyover--list-first {
                min-width: 23%;
                max-width: 25%;
                font-size: 0.875rem;
            }
        }

        &-extra {
            margin-bottom: 1.5rem;

            &.mobile {
                margin-top: 2rem;

                + .mobile {
                    margin-top: 0;
                }
            }
        }

        &-heading,
        &-item {
            position: relative;
            margin-bottom: 5px;
            padding: 0;
            border: 0;

            > button,
            > span,
            > a {
                display: block;
                padding: 0.3rem 0;
                background-color: transparent;
                text-align: left;
                width: 100%;
                border: none;
            }

            > button,
            > a {
                &:hover,
                &:focus {
                    .icon svg {
                        path,
                        circle,
                        rect,
                        ellipse {
                            stroke: $c-corporate;
                        }
                    }
                }
            }

            .bold,
            a.bold {
                font-family: $font-family-medium;
            }
       }

        &-heading {
            min-height: 3rem;

            > a,
            > span {
                font-family: $font-family-medium;
                font-size: 0.875rem;
            }

            > a {
                text-decoration: underline;
            }

            + .flyover--list-item .bold {
                margin-top: 0;
            }
        }

        &-item {
            flex-basis: calc(25% - 30px);

            .has-icon {
                padding-left: 35px;

                .icon {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    margin-top: -0.2rem;
                    margin-left: -35px;
                }
            }

            .bold {
                margin-top: 1rem;
                margin-bottom: -10px;
            }
        }
    }

    &--sublist {
        margin-left: 0;
        padding-left: 0;
        list-style-type: none;

        &-item {
            margin-top: .4rem;
            margin-bottom: .4rem;
            font-size: .9375rem;
        }
    }

    &--mobile {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        height: -webkit-fill-available;
        justify-content: space-between;

        &-menu {
            display: flex;
            flex-direction: row;
            transition: all 0.3s ease-out;
        }

        button.list-reset  {
            position: relative;
            white-space: nowrap;
            margin-left: 20px;
            padding: 0;
            padding-left: 25px;
            font-family: $font-family-medium;
            font-size: 1rem;
            color: $c-corporate;
            background-color: transparent;
            border: none;

            .magazine & {
                color: $c-white;
            }

            @include tablet {
                svg {
                    position: absolute;
                    top: 0.1rem;
                    left: -7px;
                    height: 16px;
                    transform: rotate(90deg);

                    path {
                        fill: $c-corporate;

                        .magazine & {
                            fill: $c-white;
                        }
                    }
                }
            }
        }

        &-list {
            display: block;
            min-width: 100vw;
            width: 100vw;

            ul {
                margin: 0;
                list-style: none;
                padding: 20px 24px 70px;
            }
        }
    }
}
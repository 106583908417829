﻿@import "../variables";
@import "../mixins";

.map {
    position: relative;
    height: 640px;

    &-section.page-section {
        padding-bottom: 1rem;
    }

    &-filters {
        .flex {
            display: flex;
            gap: 1.5rem;
            flex-wrap: wrap;
        }


        &-region {
            display: flex;
            flex-direction: column;
            min-width: 168px;

            .region-heading {
                font-size: 1.5rem;
                border-bottom: 1px solid black;
                padding-bottom: .7em;
                margin-bottom: 1em;
            }

            ul {
                padding: 0;
                line-height: 1.9;

                li {
                    list-style: none;

                    a {
                        color: $c-corporate;
                        font-size: 1.125rem;
                        text-decoration-line: underline;

                        &:hover {
                            color: $c-corporate-darker;
                        }
                    }
                }
            }
        }

        &-toggle {
            cursor: pointer;
            font-size: 28px;
            font-weight: bold;
            @include user-select(none);

            .map-filters-toggle-label {
                margin-right: 20px;
            }

            & > .map-filters-chevron {
                @extend .elk-icon;
                @extend .elk-chevron-up;
                transition: all 0.3s cubic-bezier(.2,.1,.2,1);
                transform-origin: 50% 55%;
            }

            &.closed {

                & > .map-filters-chevron {
                    /*@extend .elk-chevron-down;*/
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &-marker {

        &-label {
            position: absolute;
            color: $c-white;
            background-color: $c-mineshaft;
            padding: 10px;
            cursor: pointer;

            &-divisions {
                font-weight: bold;
            }
        }
    }
}

.checkbox-container {

    @include user-select(none);

    label {

        > .count {
            font-weight: normal !important;
        }
    }
}

.location {

    &-wrap {
        position: relative;
        height: 100%;
    }

    &-scroll {
        overflow-y: scroll;
    }

    &-details {
        position: absolute;
        width: auto;
        min-width: 33.3333%;
        max-width: 80%;
        height: calc(100% - 2rem);
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-color: $c-white-90;
        padding: 1.5rem;
        overflow-y: auto;

        @include tablet {
            min-width: calc(50% - 2rem);
            left: 1rem;
            max-width: 100%;
        }

        @include phone {
            min-width: calc(100% - 2rem);
            left: 1rem;
            max-width: none;
        }

        &-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            border: none;
            background: transparent;
            outline: none;
        }

        &-name {
        }

        &-address {
            border: none;
            border-radius: 0;
            padding: 0;
            margin: 0;
            white-space: pre-wrap;
            margin-bottom: 1rem;
        }

        &-phone,
        &-email {

            & > .elk-icon:first-of-type {
                display: inline-block;
                width: 1em;
                height: 1em;
                line-height: 1.5;
                margin-right: 0.5em;
            }

            &-label {
            }
        }

        &-spacer {
            margin: 20px 0px;
            height: 1px;
            border: 1px solid $c-mineshaft;
        }
    }
}

@media print {
    * {
        /* Chrome, Safari */
        -webkit-print-color-adjust: exact !important;
        /*Firefox*/
        color-adjust: exact !important;
    }

    html,
    body {
        width: 100%;
        height: auto !important;
        padding: 0;
        color: #000;
        background: #fff;
    }

    body {
        display: block;
    }

    article {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    header:not(.site-header),
    footer,
    .site-header .nav-global,
    .site-header .nav-mobile,
    .site-header .site-navigation,
    .site-header .search-and-menu-desktop,
    .site-footer,
    .breadcrumbs,
    .image-wrap,
    .placeholder-image,
    .child-pages-block,
    .news-list,
    .news-list-item,
    .block-contact-extended,
    .contentarea-fullwidth,
    .go-to-all-btn,
    .investor-info-subscription-block {
        display: none;
    }

    .site-header>.container {
        margin-bottom: 0;
    }

    .site-logo-nav {
        padding-top: 0;

        .col-md-3 {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .hero-block {
        height: auto !important;
        margin-top: 0 !important;
        margin-bottom: 1rem !important;

        * {
            padding: 0 !important;
        }
    }

    .container {
        width: 100%;
    }

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-right: 0;
        padding-left: 0;
    }

    .page-sidebar .call-to-action-block .text .cta-text:after, .col-md-4 .call-to-action-block .text .cta-text:after, .col-33 .call-to-action-block .text .cta-text:after, .col-25 .call-to-action-block .text .cta-text:after {
        display: none;
    }

    .news-list,
    .block-list {
        display: block;
        width: 100%;

        &-item {
            display: inline-block;
            padding: 0;

            page-break-inside: avoid;

            .text {
                padding: 0;
            }
        }
    }

    .block-content-page-heading {
        padding-top: 0 !important;
        padding-left: 0 !important;
    }

    .call-to-action-block {
        display: block;
        margin-bottom: 0;
        height: auto !important;

        .image {
            display: none;
        }

        .text {
            display: block;
            padding: 0;

            h2,
            .cta-text {
                display: inline-block;
            }

            .cta-text>.link-text {
                margin-left: 20px;
            }
        }
    }

    .block-list-item {
        width: auto;
        display: block;
        padding: 0 !important;
        margin-bottom: 1rem;

        .call-to-action-block {
            display: inline-block;
            height: auto;

            .image {
                display: none;
            }

            .text {
                display: block;
                padding: 0;
            }
        }
    }

    .content-block,
    .contact-block {
        padding: 0 !important;

        .text {
            padding: 0 !important;
        }
    }

    .stock-ticker-block .stock-tickers {
        padding: 0;
    }

    a[href]:after {
        //remove href for link after
        content: none !important;
    }

    a.cta:after {
        // keep button-link arrows
        content: unicode($elk-arrow-right) !important;
    }

    .page-section,
    .text {
        margin: 0;
    }

    .page-section {
        padding-top: 0;
        padding-bottom: 0;
    }

    article {
        // page-break-before: always;
        padding-top: 1cm;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img {
        page-break-after: avoid;
        page-break-inside: avoid
    }

    blockquote,
    table,
    pre {
        page-break-inside: avoid
    }

    ul,
    ol,
    dl {
        page-break-before: avoid
    }

    img {
        max-width: 100% !important;
        margin: auto;
    }

    @page {
        size: A4 portrait;
        margin: 2cm;
        -webkit-print-color-adjust: exact;
    }
}
@import "../_mixins";

.block-highlight-numbers {
    margin-bottom: 2rem;
    padding-left: 24px;
    border-left: 6px solid $c-corporate;

    @include tablet {
        margin-bottom: 1rem;
    }

    &-figure {
        margin-bottom: 0.5rem;
        font-size: 3rem;
        line-height: 1.15;
    }

    &-title {
        font-family: $font-family-medium;
    }

    &-description {
        color: $c-ash;
    }
}

.row-flex {
    display: flex;

    > [class^="col-md-"] {
        margin-top: 1rem;
        margin-bottom: 3rem;

        .block-highlight-numbers {
            height: 100%;
        }
    }

    @include phone {
        display: block;

        > [class^="col-md-"] {
            margin-bottom: 2rem;
        }
    }
}
﻿.block-content-page {
	&-heading {
		margin-bottom: 1rem !important;
		padding-top: $padding-size;

		@include phone {
			padding-top: 1rem;
		}
	}

	@include phone {
		.contentarea-extended.fullwidth + .page-section {
			padding-top: element-spacing-units(1);
		}
	}

	.section-heading {
		.article-text .body {
			font-size: 1.125rem;

			@include tablet-up {
				font-size: 1.25rem;
			}
		}
	}

	& > .page-section {
		&:last-of-type {
			padding-bottom: 0;
		}

		& > .container {
			margin-bottom: element-spacing-units(3.625);

			@include tablet {
				margin-bottom: element-spacing-units(1);
			}
		}
	}
}
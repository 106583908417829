.block-list {
    .startpage .page-section & {
        margin-bottom: element-spacing-units(4.625);

        @include phone {
            margin-bottom: element-spacing-units(1);
        }

        @include tablet {
            margin-bottom: element-spacing-units(2.75);
        }
    }
}
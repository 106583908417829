﻿.container,
.page-section,
.page-content,
.block-content-page {
    .container {
        padding-right: 0;
        padding-left: 0;
    }
}

main {
    .container {
        max-width: $max-content-width;//1196px
    }

    .hero-block .container {
        max-width: 1226px;
    }
}

.page-section .contentarea-extended.fullwidth {
    margin-top: element-spacing-units(4.625);

    @include tablet {
        margin-top: element-spacing-units(2.75);
    }

    @include phone {
        margin-top: element-spacing-units(1);
    }

    &:not(:last-child) {
        margin-bottom: element-spacing-units(4.625);

        @include tablet {
            margin-bottom: element-spacing-units(2.75);
        }

        @include phone {
            margin-bottom: element-spacing-units(1);
        }
    }
}

.hero-block + .contentarea-extended.fullwidth {
    margin-top: element-spacing-units(4.625);
    margin-bottom: element-spacing-units(4.625);
}

.page-section .container.article-content + .contentarea-extended.fullwidth {
    margin-top: element-spacing-units(1);
}

a {
    color: $c-black;
}

.btn,
body .EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action {
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    display: inline-block;
    width: auto;
    height: auto;
    font-family: $font-family-medium;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: initial;
    user-select: none;
    background: $c-white;
    border: 1px solid $c-black;
    border-radius: 2px;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        box-shadow: 0 0 0;

        &:focus {
            box-shadow: 0 0 0;
            outline: 1px solid $c-silicones;
        }
    }

    &-more {
        padding: 0.25rem 50px !important;
        font-family: $font-family-medium !important;
        border: 1px solid $c-black;
        border-radius: 4px;
    }
}
body .EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action {
    flex-basis: 140px;
    flex-shrink: 0;
}

.btn:not(:disabled):not(.disabled):active:focus,
.EPiServerForms button:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.EPiServerForms button:not(:disabled):not(.disabled).active:focus,
body .EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action:not(:disabled):not(.disabled):active:focus,
body .EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action:not(:disabled):not(.disabled).active:focus {
    outline: auto;
    outline-color: revert;
}

body {
    .EPiServerForms {
        .Form__MainBody {
            max-width: 640px;
        }

        .Form__Title {
            margin-bottom: 2rem;
        }

        .FormStep__Title {
            font-size: 1.25rem;
        }

        .Form__Element {
            &:not(.hide):not([style*="display: none"]),
            > div[lang] {
                display: flex !important;
            }

            &[type="submit"] {
                align-self: flex-start;
            }

            select {
                appearance: none;
                text-indent: 1px;
                text-overflow: '';
            }

            select::-ms-expand {
                display: none;
            }

            .submit-container {
                text-align: center;
            }

            &.FormSubmitButton {
                -webkit-flex-direction: row;
                flex-direction: row;
                display: inline-block;
                width: auto;
                padding-right: 30px !important;
                padding-left: 30px !important;
                font-family: $font-family-medium;
                text-align: center;
                color: $c-black;
                background-color: $c-white;
                border-width: 2px;
            }
        }

        .Form__NavigationBar .Form__NavigationBar__ProgressBar {
            border-color: $c-chatelle;

            .Form__NavigationBar__ProgressBar--Progress {
                background-color: $c-chatelle;
            }
        }

        .Form__Status .Form__Status__Message {
            &.hide {
                display: none;
            }
        }
    }
}
﻿@import "variables";

@mixin phone-narrow {
    @media (max-width: #{$sm-min-width - 1px}){
        @content;
    }
}

@mixin phone {
    @media (max-width: #{$md-min-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$lg-min-width - 1px}) {
        @content;
    }
}

@mixin tablet-only {
    @media (min-width: #{$md-min-width}) and (max-width: #{$lg-min-width - 1px}) {
        @content;
    }
}

@mixin tablet-up {
    @media (min-width: #{$md-min-width}) {
        @content;
    }
}

@mixin desktop-down {
    @media (max-width: #{$xl-min-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$lg-min-width}) {
        @content;
    }
}

@mixin desktop-only {
    @media (min-width: #{$lg-min-width}) and (max-width: #{$xl-min-width - 1px}) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: #{$xl-min-width}) {
        @content;
    }
}

@mixin xlarge-desktop-down {
    @media (max-width: #{$xxl-min-width - 1px}) {
        @content;
    }
}

@mixin xlarge-desktop {
    @media (min-width: #{$xxl-min-width}) {
        @content;
    }
}

@mixin flex {
    display: flex;
    display: -webkit-flex;

    &.evenly-spaced {
        justify-content: space-between;
        -webkit-justify-content: space-between;
    }

    &.wrap-items {
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
    }

    &.main-centered {
        justify-content: center;
        -webkit-justify-content: center;
    }

    &.cross-centered {
        align-content: center;
        align-items: center;
        -webkit-align-content: center;
        -webkit-align-items: center;
    }
}

@mixin debugBorder($color: rgba(0,0,0,0.1)) {
    border: 1px dashed $color;
}

@mixin list-blank {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

// A set of mixins to insert flex-box properties with
// vendor-spesific variants consistently.
@mixin display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin flex-item($grow, $shrink, $base) {
    -ms-flex: $grow $shrink $base;
    -webkit-flex: $grow $shrink $base;
    flex: $grow $shrink $base;
}

@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flex-flow($direction, $wrap) {
    -ms-flex-flow: $direction $wrap;
    -webkit-flex-flow: $direction $wrap;
    flex-flow: $direction $wrap;
}

// source: https://stackoverflow.com/a/4407335
@mixin user-select($select) {
    -webkit-touch-callout: $select; /* iOS Safari */
      -webkit-user-select: $select; /* Safari */
       -khtml-user-select: $select; /* Konqueror HTML */
         -moz-user-select: $select; /* Firefox */
          -ms-user-select: $select; /* Internet Explorer/Edge */
              user-select: $select; /* Non-prefixed version, currently supported by Chrome and Opera */
}

@mixin line-clamp($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
﻿.page-section + .contentarea-extended.fullwidth .investor-announcements-block {
    @include tablet {
        padding-right: 50px;
        padding-left: 50px;
    }

    @include phone {
        padding-right: 0;
        padding-left: 0;
    }
}

.investor-announcements-block {
    @include desktop {
        border-top: 6px solid $c-concrete;

        .article-text & {
            border-top: none;
        }
    }

    @include desktop {
        margin-top: 0;
    }

    .investor-announcements-heading {
        margin-top: 1rem;
        font-size: 2rem;

        @include phone {
            margin-top: 0;
        }
    }

    .cision-announcement-body {
        font-family: $font-family-book !important;
        font-size: $font-size-base !important;

        p {
            font-family: $font-family-book !important;
            font-size: $font-size-base !important;

            span {
                font-family: $font-family-book !important;
                font-size: $font-size-base !important;
            }
        }
    }

    .investor-announcements-list {
        margin-bottom: element-spacing-units(3);

        .announcement {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            padding-bottom: 1rem;
            text-decoration: none;
            border-bottom: 6px solid $c-concrete;

            @include tablet {
                &:first-child {
                    border-top: 6px solid $c-concrete;
                }
            }

            @include phone {
                &-image {
                    margin-bottom: 10px;
                }
            }

            @include tablet-up {
                flex-direction: row;

                &-image {
                    flex-basis: 308px;
                    padding-right: 30px;

                    + .announcement-text {
                        flex-basis: calc(100% - 308px);
                    }
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                margin-top: 2.5rem;
                margin-bottom: 1.5rem;

                @include tablet-up {
                    align-self: center;
                }

                @include tablet {
                    margin-top: 1rem;
                    margin-bottom: 0;
                }
            }

            &-title {
                font-family: $font-family-medium;
                font-size: 1.125rem;

                @include phone {
                    font-size: 1rem;
                }
            }

            &-date {
                margin-bottom: 0.5rem;
                font-size: .875rem;
                color: $c-ash;

                @include phone {
                    font-size: .75rem;
                }
            }

            &:hover,
            &:focus {
                text-decoration: none;
                color: currentColor;
                border-bottom-color: $c-corporate;

                .announcement-title {
                    text-decoration: underline;
                }
            }
        }
    }

    .investor-announcements-btn {
        margin-bottom: 2rem;

        &:first-child {
            margin-top: 2rem;
        }

        @include tablet {
            position: absolute;
            top: 0;
            right: 15px;
            margin-bottom: 0;

            &:first-child {
                margin-top: -3rem;
            }
        }

        @include phone {
            display: none;

            &-phone {
                display: inline-block;
                width: 100%;
                max-width: 400px;
                margin-bottom: 2rem;
            }
        }
    }

    .investor-announcements-phone {
        display: none;

        @include phone {
            display: block;
            text-align: center;
        }
    }
}
.expandable-section {
    margin-top: -1px;

    .contact-us-block__emphasize & {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        background-color: $c-white;
    }

    &__content {
        padding-left: 44px;
        font-size: 1.125rem;

        @include phone {
            font-size: 1rem;
        }

        .contact-us-block__emphasize & {
            padding-right: 80px;
        }
    }

    details {
        position: relative;
        border-top: 1px solid $c-elkem-grey;
        border-bottom: 1px solid $c-elkem-grey;

        .stripe--odd & {
            background-color: $c-white;
            border-top: none;
            border-bottom: none;
        }

        summary {
            list-style: none;
            padding-top: 1rem;
            padding-bottom: 1rem;

            .contact-us-block__emphasize & {
                padding-right: 60px;
                padding-left: 1rem;
            }

            &::marker,
            &::-webkit-details-marker {
                display: none;
            }

            h3 {
                position: relative;
                margin-bottom: 0;
                margin-left: 44px;
                font-size: 1.25rem;
                color: $c-charcoal;

                @include phone {
                    margin-left: 28px;
                    font-size: 1.125rem;
                }

                span.icon {
                    display: inline-block;
                    position: absolute;
                    top: 1px;
                    left: -44px;
                    width: 23px;
                    height: 23px;
                    transition: transform .2s ease-in-out;

                    @include phone {
                        left: -28px;

                        &,
                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .cta-link {
            color: $c-corporate;
            background-color: transparent;
            border: none;

            &:hover,
            &:focus {
                color: $c-black;
                background-color: transparent;
            }

            .elk-icon {
                margin-right: 8px;
            }
        }

        .category-icon {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 30px;
            padding: 16px;
            display: inline-flex;
            justify-content: center;

            svg {
                width: 30px;
                height: 30px;
            }
        }

        &.theme--silicones {
            summary:hover {
                background-color: $c-silicones;
            }

            .category-icon {
                background-color: $c-silicones;
            }
        }

        &.theme--silicon-products {
            summary:hover {
                background-color: $c-silicon-products;
            }

            .category-icon {
                background-color: $c-silicon-products;
            }
        }

        &.theme--carbon-solutions {
            summary:hover {
                background-color: $c-carbon;
            }

            .category-icon {
                background-color: $c-carbon;
            }
        }

        &.theme--corporate {
            summary:hover {
                background-color: $c-corporate;
            }

            .category-icon {
                background-color: $c-corporate;
            }
        }

        &[open] {
            padding-bottom: 1rem;

            .expandable-section__content {
                padding-top: 2rem;
                padding-bottom: 2rem;

                .contact-us-block__emphasize & {
                    padding-bottom: 1rem;
                }

                @include phone {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
            }

            summary {
                &,
                &:hover {
                    background-color: $c-white;
                }

                h3 span.icon {
                    transform: rotate(45deg);
                }
            }
        }
    }
}

div:has(.expandable-section) + div:has(.section-heading) {
    margin-top: 2rem;
}
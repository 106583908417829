﻿.stock-ticker-block {
    .stock-tickers {
        background-color: $c-gray;
        padding: element-spacing-units(2);
        margin-bottom: element-spacing-units(3);

        .elk-arrow-right {
            &.negative-change {
                transform: rotate(45deg);
            }

            &.no-change {
                transform: rotate(0deg);
            }

            &.positive-change {
                transform: rotate(-45deg);
            }
        }

        table {
            font-size: 1rem;
            line-height: 1.6rem;

            tr {
                td:first-child {
                    font-weight: bold;
                    padding-right: 1rem;
                }
            }
        }

        .stock-price {
            color: $c-blue;
            font-weight: 500;
            font-size: 2rem;
            margin-bottom: 0.8rem;
        }

        .ticker-time {
            margin-bottom: 1.5rem;
        }
    }
}

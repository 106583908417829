﻿@import "../_variables.scss";
@import "../_mixins.scss";

.video {
    &-block {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;

        .block-list &,
        .news-list & {
            @include flex-item(1,0,auto);
            margin-bottom: 0;
        }

        video {
            width: 100%;
        }
    }

    &-overlay {
        &-panel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,255,0.2);
            color: white;
        }
    }

    &-wrap {
        position: relative;
        padding-bottom: 56.25%;

        .contentarea-fullwidth & {
            padding-bottom: 40%;


            @include tablet {
                padding-bottom: 56.25vw;
            }
        }

        & > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
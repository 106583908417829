﻿@font-face {
	font-family: 'ABC Favorit Book';
	src:url('/Static/fonts/ABCFavorit-Book.woff2') format('woff2'),
		url('/Static/fonts/ABCFavorit-Book.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'ABC Favorit Book Italic';
	src:url('/Static/fonts/ABCFavorit-BookItalic.woff2') format('woff2'),
		url('/Static/fonts/ABCFavorit-BookItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'ABC Favorit Medium';
	src:url('/Static/fonts/ABCFavorit-Medium.woff2') format('woff2'),
		url('/Static/fonts/ABCFavorit-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'ABC Favorit Medium Italic';
	src:url('/Static/fonts/ABCFavorit-MediumItalic.woff2') format('woff2'),
		url('/Static/fonts/ABCFavorit-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

$icomoon-font-path: "/Static/fonts" !default;
$elk-globe:			\e900;
$elk-map-pin:		\e901;
$elk-search:		\e902;
$elk-email:			\e903;
$elk-phone:			\e904;
$elk-form:			\e905;
$elk-facebook:		\e906;
$elk-twitter:		\e907;
$elk-youtube:		\e908;
$elk-linkedin:		\e909;
$elk-chevron-down:	\e90b;
$elk-chevron-up:	\e90c;
$elk-close:			\e90d;
$elk-checkmark:		\e90e;
$elk-checkmark-fat: \e90f;
$elk-menu:			\e910;
$elk-arrow-left:	\e90a;
$elk-arrow-right:	\e911;
$elk-arrow-up:		\e912;
$elk-arrow-down:	\e913;
$elk-clock:			\e914;
$elk-calendar:		\e915;

@font-face {
	font-family: 'elk-icons';
	src: url('#{$icomoon-font-path}/elk-icons.woff2?e7nuln') format('woff2'), url('#{$icomoon-font-path}/elk-icons.woff?e7nuln') format('woff');
	font-weight: normal;
	font-style: normal;
}

[class^="elk-"], [class*=" elk-"] {
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
	@return unquote("\"") + $str + unquote("\"");
}

i.elk {
	&-icon::before {
		display: inline-block;
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'elk-icons' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&-globe::before {
		content: unicode($elk-globe);
	}

	&-map-pin::before {
		content: unicode($elk-map-pin);
	}

	&-search::before {
		content: unicode($elk-search);
	}

	&-email::before {
		content: unicode($elk-email);
	}

	&-phone::before {
		content: unicode($elk-phone);
	}

	&-form::before {
		content: unicode($elk-form);
	}

	&-contact::before {
		content: unicode($elk-form);
	}

	&-facebook::before {
		content: unicode($elk-facebook);
	}

	&-twitter::before {
		content: unicode($elk-twitter);
	}

	&-youtube::before {
		content: unicode($elk-youtube);
	}

	&-linkedin::before {
		content: unicode($elk-linkedin);
	}

	&-arrow-up::before {
		content: unicode($elk-arrow-up);
	}

	&-arrow-right::before {
		content: unicode($elk-arrow-right);
	}

	&-arrow-down::before {
		content: unicode($elk-arrow-down);
	}

	&-arrow-left::before {
		content: unicode($elk-arrow-left);
	}

	&-chevron-down::before {
		content: unicode($elk-chevron-down);
	}

	&-chevron-up::before {
		content: unicode($elk-chevron-up);
	}

	&-close::before {
		content: unicode($elk-close);
	}

	&-checkmark::before {
		content: unicode($elk-checkmark);
	}

	&-checkmark-fat::before {
		content: unicode($elk-checkmark-fat);
	}

	&-menu::before {
		content: unicode($elk-menu);
	}

	&-clock::before {
		content: unicode($elk-clock);
	}

	&-calendar::before {
		content: unicode($elk-calendar);
	}
}
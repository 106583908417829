﻿.blacksite {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    color: $c-white;
    background-color: $c-mineshaft;
    z-index: 10000;

    h1 {
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    a {
        color: $c-white;
        text-decoration: underline;
    }
}
